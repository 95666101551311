import React, { FC, useLayoutEffect, useRef } from 'react';
import { Box } from '@components/Box';
import { gsap } from 'gsap';

interface LetterSliderProps {
  content: readonly string[];
  style?: React.CSSProperties;
  className?: string;
}

const LetterSlider: FC<LetterSliderProps> = props => {
  const { content, style, className } = props;
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const tl = gsap.timeline({ repeat: -1 });

    let wordIndex = 0;
    const target = contentRef.current;
    const highestWordLength = content.reduce((highestCount, word) => Math.max(highestCount, word.length), 0);
    const baseDuration = 0.04 * highestWordLength;
    const totalDuration = baseDuration * content.length;
    if (target) {
      tl.to(
        {},
        {
          duration: totalDuration,
          repeat: -1,
          onRepeat: () => {
            const word = content[wordIndex];

            const wordSpans = word.split('').map((char, index) => {
              const span = document.createElement('span');
              span.textContent = char;
              return span;
            });

            target.innerHTML = ''; // Clear the previous text
            wordSpans.forEach(span => target.appendChild(span));

            gsap.from(wordSpans, {
              duration: 0.04 * highestWordLength,
              opacity: 0,
              stagger: (0.04 * highestWordLength) / word.length,
              onComplete: () => {
                gsap.to(wordSpans.reverse(), {
                  delay: 0.5,
                  duration: 0.04 * highestWordLength,
                  opacity: 0,
                  stagger: (0.04 * highestWordLength) / word.length,
                });
              },
            });
            wordIndex = (wordIndex + 1) % content.length;
          },
          onComplete: () => {
            tl.seek(0); // Reset the timeline to start immediately after completion
          },
        }
      );
    }
    return () => {
      tl.kill(); // Kill the timeline on component unmount
    };
  }, [content]);

  return <Box ref={contentRef} style={style} className={className}></Box>;
};

export default LetterSlider;
