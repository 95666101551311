import { useCallback, useEffect, useState } from 'react';

type LoadImageRetryReturn = [number, () => boolean];

const TIMEOUT = [1000, 5000, 10000, 15000];

export function useImageRetry(): LoadImageRetryReturn {
  const [retryCounter, setRetryCounter] = useState(0);
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout> | null>(null);

  const setRetryCounterValue = useCallback(() => {
    if (retryCounter < 4) {
      setTimerId(
        setTimeout(() => {
          setRetryCounter(prevCounter => prevCounter + 1);
        }, TIMEOUT[retryCounter])
      );
      return true;
    }
    return false;
  }, [retryCounter]);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  return [retryCounter, setRetryCounterValue];
}
