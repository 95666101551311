import React from 'react';
import { Flex } from '@components/Flex';
import { headerData } from '@data-ts/shared/header';
import { CopyrightText, StyledFooterLink } from './Footer.styles';

const footerNavData = headerData.footer;

interface FooterPrivacyMenuProps {
  id: string;
}

export const FooterPrivacyMenu: React.FC<FooterPrivacyMenuProps> = ({ id }) => {
  return (
    <>
      <Flex alignItems="center" style={{ columnGap: '4px' }}>
        <StyledFooterLink id={`footer2Terms${id}`} href="https://withjoy.com/eula" variant="footer" hasSpan={false}>
          {footerNavData.terms}
        </StyledFooterLink>
        <CopyrightText variant="navParent18" tagName="span">
          &
        </CopyrightText>
        <StyledFooterLink id={`footer2privacy${id}`} href="https://privacy.withjoy.com" variant="footer" hasSpan={false}>
          {footerNavData.privacy}
        </StyledFooterLink>
      </Flex>
      <StyledFooterLink
        id={`footerPrivacyNotice${id}`}
        variant="footer"
        href="https://privacy.withjoy.com/privacy-policy#california-privacy-notice"
        noreferrer
        noopener
      >
        {footerNavData.californiaPrivacyNotice}
      </StyledFooterLink>
      <StyledFooterLink id={`footerDonotShareNotice${id}`} variant="footer" href="https://privacy.withjoy.com/opt-out" noreferrer noopener>
        {footerNavData.doNotShareInformation}
      </StyledFooterLink>
    </>
  );
};
