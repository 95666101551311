export const footerPageId = ({ page }: { page?: MarcomPageName }) => {
  switch (page) {
    case 'home':
      return 'Home_Footer';
    case 'wedding-website':
      return 'Wedding_Websites_Footer';
    case 'guest-list':
      return 'Guest_List_Footer';
    case 'online-rsvp':
      return 'RSVP_Footer';
    case 'save-the-date':
      return 'Save_the_Dates_Footer';
    case 'registry':
      return 'Registry_Footer';
    case 'app':
      return 'App_Footer';
    default:
      return 'mainLayoutFooter';
  }
};

export const abcSectionSectionId = ({ page }: { page?: MarcomPageName }) => {
  switch (page) {
    case 'home':
      return 'Home_ABC_CTA';
    case 'wedding-website':
      return 'Wedding_Websites_ABC_CTA';
    case 'guest-list':
      return 'Guest_List_ABC_CTA';
    case 'online-rsvp':
      return 'RSVP_ABC_CTA';
    case 'save-the-date':
      return 'Save_the_Dates_ABC_CTA';
    case 'registry':
      return 'Registry_ABC_CTA';
    case 'app':
      return 'App_ABC_CTA';
    default:
      return 'planningShoesVideoContainer';
  }
};
