import styled from 'styled-components';
import { animationTransition } from '@utils/animationTransition';
import { themeMediaQuery } from '@utils/styledTheme.util';
import { AllButtonProps } from '@components/Button';
import { variant } from 'styled-system';

const secondaryActionStates = {
  '&:hover': {
    backgroundColor: 'secondaryCtaHover',
    color: 'actionPurple4',
    borderColor: 'actionPurple3',
  },
  '&:active': {
    backgroundColor: 'secondaryCtaPress',
    color: 'actionPurple2',
    borderColor: 'actionPurple2',
  },
};

const secondaryHover = 'rgba(255, 255, 255, 0.2)';
const secondaryActive = 'rgba(255, 255, 255, 0.4)';

const variants = {
  primary: {
    backgroundColor: 'ctaDefault',
    color: 'mono7',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'ctaHover',
    },
    '&:active': {
      backgroundColor: 'ctaPress',
    },
  },
  secondary: {
    backgroundColor: 'secondaryCtaDefault',
    color: 'actionPurple3',
    borderColor: 'actionPurple3',
    ...secondaryActionStates,
  },
  altYellow: {
    backgroundColor: 'transparent',
    color: 'yellow1',
    borderColor: 'yellow1',
    ...secondaryActionStates,
  },
  altBlueSecondary: {
    backgroundColor: 'transparent',
    color: 'mono7',
    borderColor: 'mono7',
    '&:hover': {
      backgroundColor: secondaryHover,
      color: 'blue3',
      borderColor: 'blue3',
    },
    '&:active': {
      backgroundColor: secondaryActive,
      color: 'blue3',
      borderColor: 'blue3',
    },
  },
  altPurpleSecondary: {
    backgroundColor: 'transparent',
    color: 'mono7',
    borderColor: 'mono7',
    '&:hover': {
      backgroundColor: secondaryHover,
      color: 'purple3',
      borderColor: 'purple3',
    },
    '&:active': {
      backgroundColor: secondaryActive,
      color: 'purple3',
      borderColor: 'purple3',
    },
  },
  altGreenSecondary: {
    backgroundColor: 'transparent',
    color: 'mono7',
    borderColor: 'mono7',
    '&:hover': {
      backgroundColor: secondaryHover,
      color: 'green3',
      borderColor: 'green3',
    },
    '&:active': {
      backgroundColor: secondaryActive,
      color: 'green3',
      borderColor: 'green3',
    },
  },
  altRedSecondary: {
    backgroundColor: 'transparent',
    color: 'mono7',
    borderColor: 'mono7',
    '&:hover': {
      backgroundColor: secondaryHover,
      color: 'red3',
      borderColor: 'red3',
    },
    '&:active': {
      backgroundColor: secondaryActive,
      color: 'red3',
      borderColor: 'red3',
    },
  },
  yellowPrimary: {
    backgroundColor: 'yellow1',
    color: 'actionPurple3',
    borderColor: 'actionPurple3',
    ...secondaryActionStates,
  },
  redPrimary: {
    backgroundColor: 'red6',
    color: 'mono7',
    borderColor: 'mono7',
    '&:hover': {
      backgroundColor: 'red3',
      color: 'mono7',
      borderColor: 'mono7',
    },
    '&:active': {
      backgroundColor: 'red2',
      color: 'mono7',
      borderColor: 'mono7',
    },
  },
  prime: {
    backgroundColor: 'white',
    color: 'primeTextBlue',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'primeHoverBlue',
      color: 'white',
      borderColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'primeActiveBlue',
      color: 'white',
      borderColor: 'transparent',
    },
  },
  primeWeddingWebsites: {
    backgroundColor: 'mono7',
    color: 'ctaDefault',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'ctaHover',
      color: 'white',
      borderColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'ctaPress',
      color: 'white',
      borderColor: 'transparent',
    },
  },
  bluePrimary: {
    backgroundColor: 'blue4',
    color: 'mono7',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'blue5',
      color: 'mono7',
      borderColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'blue3',
      color: 'mono7',
      borderColor: 'transparent',
    },
  },
  purplePrimary: {
    backgroundColor: 'transparent',
    color: 'purple5',
    borderColor: 'purple5',
    '&:hover': {
      backgroundColor: 'purple3',
      color: 'purple5',
    },
    '&:active': {
      backgroundColor: 'purple4',
      color: 'white',
    },
  },
  invertedPurplePrimary: {
    backgroundColor: 'white',
    color: 'purple5',
    borderColor: 'white',
    '&:hover': {
      backgroundColor: 'purple2',
      borderColor: 'purple2',
      color: '#7139BF',
    },
    '&:active': {
      backgroundColor: 'purple5',
      borderColor: 'purple5',
      color: 'purple3',
    },
  },
  invertedPurpleSecondary: {
    backgroundColor: 'purple6',
    borderColor: 'white',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderColor: 'purple3',
      color: 'purple3',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: 'purple3',
      color: 'purple3',
    },
  },
  invertedBluePrimary: {
    backgroundColor: 'white',
    borderColor: 'white',
    color: 'blue6',
    '&:hover': {
      backgroundColor: 'blue2',
      borderColor: 'blue2',
      color: 'blue5',
    },
    '&:active': {
      backgroundColor: 'blue5',
      borderColor: 'blue5',
      color: 'blue3',
    },
  },
  invertedBlueSecondary: {
    backgroundColor: 'blue6',
    borderColor: 'white',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderColor: 'blue3',
      color: 'blue3',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: 'blue3',
      color: 'blue3',
    },
  },
  invertedGreenPrimary: {
    backgroundColor: 'white',
    borderColor: 'white',
    color: 'green6',
    '&:hover': {
      backgroundColor: 'green2',
      borderColor: 'green2',
      color: 'green5',
    },
    '&:active': {
      backgroundColor: 'green5',
      borderColor: 'green5',
      color: 'green3',
    },
  },
  invertedGreenSecondary: {
    backgroundColor: 'green6',
    borderColor: 'white',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderColor: 'green3',
      color: 'green3',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: 'green3',
      color: 'green3',
    },
  },
  invertedRedPrimary: {
    backgroundColor: 'white',
    borderColor: 'white',
    color: 'red6',
    '&:hover': {
      backgroundColor: 'red2',
      borderColor: 'red2',
      color: 'red5',
    },
    '&:active': {
      backgroundColor: 'red5',
      borderColor: 'red5',
      color: 'red3',
    },
  },
  invertedRedSecondary: {
    backgroundColor: 'red6',
    borderColor: 'white',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderColor: 'red3',
      color: 'red3',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: 'red3',
      color: 'red3',
    },
  },
  darkGetStartedBtn: {
    backgroundColor: 'white',
    color: 'purple4',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'purple4',
      borderColor: 'purple4',
      color: 'white',
    },
    '&:active': {
      backgroundColor: 'rgba(68, 0, 102, 1)',
      borderColor: 'rgba(68, 0, 102, 1)',
      color: 'white',
    },
  },
  darkfindACoupleBtn: {
    backgroundColor: 'transparent',
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderColor: 'transparent',
      color: 'linkHover',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      borderColor: 'transparent',
      color: 'linkPress',
    },
  },
  purpleTransparentBtn: {
    backgroundColor: 'transparent',
    color: 'linkDefault',
    borderColor: 'linkDefault',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: 'linkHover',
      color: 'linkHover',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      borderColor: 'linkPress',
      color: 'linkPress',
    },
  },
  defaultPrimary: {
    backgroundColor: 'mono1',
    color: 'white',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'mono12',
    },
    '&:active': {
      backgroundColor: 'rgba(118, 118, 118, 1)',
    },
  },
  defaultPrimeWeddingWebsites: {
    backgroundColor: 'yellow1',
    color: 'black',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'secondaryCtaHover',
      color: 'actionPurple4',
      borderColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'secondaryCtaPress',
      color: 'actionPurple2',
      borderColor: 'transparent',
    },
  },
};

export type ButtonVariant = keyof typeof variants;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledButton = styled('button').attrs({ typography: 'featureHed17' })<AllButtonProps>`
  display: inline-block;
  ${variant({
    prop: 'typography',
    scale: 'typography',
  })};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 32px;
  text-align: center;
  white-space: nowrap;
  font-size: 17px;
  height: 56px;
  line-height: 54px;
  border-width: 2px;
  border-style: solid;
  transition: ${animationTransition('color', 'background-color', 'border-color')};
  ${variant({ variants })}
  ${themeMediaQuery('sm')} {
    height: 48px;
    line-height: 46px;
    border-width: 1px;
  }
`;
