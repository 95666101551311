import React, { useEffect, useState } from 'react';
import { Flex } from '@components/Flex';
import { Container, NavIcon, NavText } from './FooterNavs.styles';
import { NavBarData, navType } from '@data-ts/components/FooterNavs/FooterNavs';
import { CTALink } from '@components/CTALink';
import { useShopStorefrontContext } from '@utils/storefront/ShopStorefrontProvider';

// const handleClick = (nav: navType) => {
//   if (nav.path) {
//     if (window.location.pathname !== nav.path) {
//       window.location.pathname = nav.path;
//     }
//   }
// };

export const FooterNavs = () => {
  const { config } = useShopStorefrontContext();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [isHidden, updateStatus] = useState(false);
  const [isIos15Safari, setIsIos15Safari] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      // Prevent the android chrome mini-infobar from appearing on mobile
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    setActiveTab(window.location.pathname);
  }, []);

  useEffect(() => {
    let oldScroll = window.pageYOffset;
    let scrollDownStart: number | null = null;
    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset;
      const scrollDiff = oldScroll - currentScroll;
      if (scrollDiff < 0) {
        // Handle Scroll down
        if (!scrollDownStart) {
          scrollDownStart = currentScroll;
        }
        if (scrollDownStart && currentScroll - scrollDownStart > 600) {
          if (!isHidden) {
            scrollDownStart = null;
            updateStatus(true);
          }
        }
      } else if (scrollDiff > 0) {
        // Handle Scroll up
        if (isHidden) {
          scrollDownStart = null;
          updateStatus(false);
        }
      }
      oldScroll = currentScroll;
    });
    setIsIos15Safari(!!window.navigator.userAgent.match('OS 15_') && !!window.navigator.userAgent.match('Safari'));
  }, [isHidden]);

  if (!config.mobileFooterNavEnabled) {
    return null;
  }

  return (
    <Container isHidden={isHidden} isIos15Safari={isIos15Safari}>
      {NavBarData.map((navs: navType) => {
        const isActive = activeTab === navs.path;
        return (
          <CTALink key={navs.id} id={navs.id} href={navs.path || ''} variant="footerAlt">
            <Flex flexDirection="column" alignItems="center">
              <NavIcon name={navs.icon} color="neutra5" marginBottom={[1]} isActive={isActive} />
              <NavText variant="legal" tagName="span" color="neutra5" isActive={isActive} name={navs.name}>
                {navs.name}
              </NavText>
            </Flex>
          </CTALink>
        );
      })}
    </Container>
  );
};
