import { BreakpointSize } from '@components/themes';
import { useTheme } from '@styledComponents';
import { useMediaQuery } from 'react-responsive';

/**
 * Hook checks if devices matches given media query from theme
 * @param breakpointSize - size of breakpoint from theme
 * @return null in case query not found or incorrect and boolean otherwise(true if current device matches query and false otherwise)
 */
export const useThemeMediaQuery = (breakpointSize: BreakpointSize): boolean | null => {
  const theme = useTheme();
  let query = theme.mediaQueries[breakpointSize];
  query = query && query.replace(breakpointSize === 'tablet' ? '@media ' : '@media screen and ', '');
  let isMatches = useMediaQuery({ query: query || '(max-width: 1px)' });
  return query ? isMatches : null;
};
