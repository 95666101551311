import React from 'react';
import { ColumnWrapper } from './Column.styles';
import { useColumnsContext } from './Columns.context';
import { ColumnWidth } from './Columns.types';

export interface ColumnProps
  extends Readonly<{
    /**
     * Column width for mobile viewport - [0px, 768px]
     *
     * If the parent `<Columns />` component has `stackOnMobile` enabled, this prop will be ignored.
     *
     * To activate column behavior on mobile, set `stackOnMobile` to **false**.
     */
    mobile?: ColumnWidth;
    /**
     * Column offset width for mobile viewport - [0px, 768px]
     
     * If the parent `<Columns />` component has `stackOnMobile` enabled, this prop will be ignored.
     *
     * To activate column behavior on mobile, set `stackOnMobile` to **false**.
     */
    mobileOffset?: ColumnWidth;

    /** Column width for tablet viewport and upwards - [769px, --] */
    tablet?: ColumnWidth;
    /** Column offset width for tablet viewport and upwards - [769px, --] */
    tabletOffset?: ColumnWidth;

    /** Column width for tablet viewport and upwards - [1152px, --] */
    desktop?: ColumnWidth;
    /** Column offset width for tablet viewport and upwards - [1152px, --] */
    desktopOffset?: ColumnWidth;

    /** Column width for tablet viewport and upwards - [1440px, --] */
    widescreen?: ColumnWidth;
    /** Column offset width for tablet viewport and upwards - [1440px, --] */
    widescreenOffset?: ColumnWidth;

    /**
     * Base column width for all viewports - this can be overriden by any of the viewport specific widths.
     *
     * If the parent `<Columns />` component has `stackOnMobile` enabled, this prop will be ignored.
     *
     * To activate column behavior on mobile, set `stackOnMobile` to **false**.
     */
    width?: ColumnWidth;
    /**
     * Base column offset width for all viewports - this can be overriden by any of the viewport specific widths
     *
     * If the parent `<Columns />` component has `stackOnMobile` enabled, this prop will be ignored.
     *
     * To activate column behavior on mobile, set `stackOnMobile` to **false**.
     */
    offset?: ColumnWidth;
  }> {}

const Column: React.FC<ColumnProps> = ({ children, ...restProps }) => {
  const context = useColumnsContext();
  return (
    <ColumnWrapper {...restProps} {...context} paddingX={[3, undefined, 4]}>
      {children}
    </ColumnWrapper>
  );
};

Column.displayName = 'Column';

export { Column };
