import styled from 'styled-components';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { ColumnsContextType } from './Columns.context';
import { createColumnSizeRules, getColumnGap } from './Column.utils';
import { ColumnProps } from './Column';
import { MarcomPaddingProps, padding } from 'styled-system';

const shouldForwardProp = createShouldForwardProp([
  // Columns context values
  'columnGap',

  // Column Props
  'mobileOffset',
  'mobile',

  'tablet',
  'tabletOffset',

  'desktop',
  'desktopOffset',

  'widescreen',
  'widescreenOffset',

  'width',
  'offset',
]);

export type ColumnInternalProps = ColumnProps & Pick<MarcomPaddingProps, 'paddingX'> & ColumnsContextType;

export const ColumnWrapper = styled.div.attrs({ shouldForwardProp })<ColumnInternalProps>`
  /* Baseline */
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 ${props => getColumnGap(props.theme, 'md')};
  ${padding};
  ${createColumnSizeRules}
`;
