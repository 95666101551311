import { useEffect } from 'react';
import { withWindow } from '@utils/withWindow';
import { useQueryParams } from '@hooks/useQueryParams';
import { Integrations } from '@static/js/types';
import { EXP_FEATURE_FLAG } from './experiments.constants';
import { useIsMounted } from '@hooks/useIsMounted';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';
import { segment } from '@static/js/joy';
import { addAction } from '@utils/logger';

export enum ExperimentsTelemetryDevice {
  both,
  desktop,
  mobile,
}

interface ExperimentsTelemetry {
  /**
   * Name of telemetry event
   */
  name: string;
  /**
   * Device on which screen telemetry event will send
   * desktop | mobile | both
   * Default - both
   */
  device?: ExperimentsTelemetryDevice;
  /**
   * Prefix used with Experiment & Control in telemetry event value
   */
  valuePrefix?: string;
  skip?: boolean;
}

const EXPERIMENTS_KEY = 'joy_marcom_experiments';

export function useExperimentsTelemetry({
  name,
  device = ExperimentsTelemetryDevice.both,
  valuePrefix,
  skip = false,
}: ExperimentsTelemetry) {
  const query = useQueryParams();
  const isMounted = useIsMounted();
  const isMobile = useThemeMediaQuery('below_sm');

  useEffect(() => {
    withWindow(global => {
      if (!isMounted || skip) return;
      if ((isMobile && device === ExperimentsTelemetryDevice.desktop) || (!isMobile && device === ExperimentsTelemetryDevice.mobile)) {
        return;
      }

      const queryVersion = query[`${EXP_FEATURE_FLAG}${name}`];
      const storageVersion = global.localStorage.getItem(EXPERIMENTS_KEY);
      const parsedAbTests = storageVersion ? JSON.parse(storageVersion)[name] : null;
      const abTestVersion = parseInt(queryVersion ?? parsedAbTests);
      const value = `${valuePrefix ?? ''}${abTestVersion > 0 ? `Experiment${abTestVersion > 1 ? `V${abTestVersion}` : ''}` : 'Control'}`;

      segment.sendTelemetryEvent('ABTestAssigned', { name, value }, undefined, { integrations: { [Integrations.Amplitude]: true } });
      addAction('ABTestAssigned', { name, value });
    });
  }, [device, isMobile, isMounted, name, query, skip, valuePrefix]);
}
