import React, { useState, useEffect, useMemo } from 'react';
import { Flex } from '@components/Flex';
import { JoykitIcon } from '@components/JoykitIcon';
import { headerData } from '@data-ts/shared/header';
import { AllButtonProps } from '@components/Button';
import { TRANSITION_INTERVAL } from 'src/layout/Layout.constants';
import { useShopStorefrontContext } from '@utils/storefront/ShopStorefrontProvider';
import { StyledNavPropsButton, ValuePropsContainer, ValuePropsContent } from './ValueProps.styles';
import { useAcquisitionContext } from 'src/gatsby-browser/acquisition-context-tracking/useAcquisitionContext';

const hotelRoomBlockValueProps = headerData.hotelRoomBlockValueProps;

interface ValuePropsProps {
  toggleValueProps: () => void;
}

export const ValueProps: React.FC<ValuePropsProps> = ({ toggleValueProps }) => {
  const { config } = useShopStorefrontContext();
  const [, checkAcquisitionContext] = useAcquisitionContext();
  const [activeValuePropVariant, setActiveValuePropVariant] = useState<number>(0);

  const hasAcquisitionContext = checkAcquisitionContext('utm_campaign', '[SAGE]-Wedding-Website-New-MarComm-Travel-Segments-Only');

  const valueProps = hasAcquisitionContext ? hotelRoomBlockValueProps : config.valuePropCardsData;

  const buttonProps: AllButtonProps = useMemo(
    () =>
      hasAcquisitionContext
        ? { id: 'universalNavValuePropsBtn', forwardedAs: 'a', href: '/hotel-room-blocks' }
        : { id: 'universalNavValuePropsBtn', forwardedAs: 'button', onClick: toggleValueProps },
    [hasAcquisitionContext, toggleValueProps]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveValuePropVariant(prev => (prev + 1) % valueProps.length);
    }, TRANSITION_INTERVAL);
    return () => clearInterval(interval);
  }, [valueProps]);

  return (
    <StyledNavPropsButton variant="defaultPrimary" {...buttonProps}>
      {valueProps.map((card, idx) => (
        <ValuePropsContainer key={idx}>
          <ValuePropsContent show={idx === activeValuePropVariant}>
            <Flex>{card.title}</Flex>
            <JoykitIcon name="forwardChevron" color="white" size={16} />
          </ValuePropsContent>
        </ValuePropsContainer>
      ))}
    </StyledNavPropsButton>
  );
};
