import React from 'react';
import { createContext } from '@utils/createContext';
import { useCallback, useMemo, useState } from 'react';
import { MAIN_MENU_OPTIONS, FOOTER_MENU_OPTIONS, VALUE_PROP_CARDS_BY_STOREFRONT, FOOTER_MAIN_MENU_OPTIONS } from './storefront.config';
import { CatalogStorefront, HeaderMainMenus, FooterMainMenus, ValuePropCardsData } from './storefront.types';

type ShopStorefrontContext = {
  currentStorefront: CatalogStorefront;
  changeStorefront: (nextStorefront: CatalogStorefront) => void;
  isBabyStorefront: boolean;
  isWeddingStorefront: boolean;
  config: {
    homePageUrl: string;
    mobileFooterNavEnabled: boolean;
    mainMenuOptions: HeaderMainMenus;
    footerMenuOptions: FooterMainMenus;
    footerMainMenuOptions: FooterMainMenus;
    valuePropCardsData: ValuePropCardsData;
  };
};

const [Provider, useShopStorefrontContext] = createContext<ShopStorefrontContext>({ name: 'ShopStorefrontProvider' });

type ShopStorefrontProviderProps = {
  page?: MarcomPageName;
  children: React.ReactNode;
};

export const CONFIG_BY_STOREFRONT: Record<CatalogStorefront, ShopStorefrontContext['config']> = {
  baby: {
    homePageUrl: '/baby-registry',
    mobileFooterNavEnabled: false,
    mainMenuOptions: MAIN_MENU_OPTIONS.baby,
    footerMenuOptions: FOOTER_MENU_OPTIONS.baby,
    footerMainMenuOptions: FOOTER_MAIN_MENU_OPTIONS.baby,
    valuePropCardsData: VALUE_PROP_CARDS_BY_STOREFRONT.baby,
  },
  wedding: {
    homePageUrl: '/',
    mobileFooterNavEnabled: true,
    mainMenuOptions: MAIN_MENU_OPTIONS.wedding,
    footerMenuOptions: FOOTER_MENU_OPTIONS.wedding,
    footerMainMenuOptions: FOOTER_MAIN_MENU_OPTIONS.wedding,
    valuePropCardsData: VALUE_PROP_CARDS_BY_STOREFRONT.wedding,
  },
};

export const ShopStorefrontProvider = (props: ShopStorefrontProviderProps) => {
  const [storefront, setStorefront] = useState<CatalogStorefront>(() => {
    // We have only wedding and baby storefronts for now, later on we could update this logic
    return props.page === 'baby-registry' ? CatalogStorefront.baby : CatalogStorefront.wedding;
  });

  const changeStorefront = useCallback((nextStorefront: CatalogStorefront) => {
    setStorefront(nextStorefront);
  }, []);

  const context = useMemo<ShopStorefrontContext>(() => {
    return {
      changeStorefront,
      currentStorefront: storefront,
      config: CONFIG_BY_STOREFRONT[storefront],
      isBabyStorefront: storefront === CatalogStorefront.baby,
      isWeddingStorefront: storefront === CatalogStorefront.wedding,
    };
  }, [changeStorefront, storefront]);
  return <Provider value={context}>{props.children}</Provider>;
};

export { useShopStorefrontContext };
