import { ColumnWidth, ColumnGap, ViewportSize } from './Columns.types';
import { DefaultTheme, css, CSSObject, StyledProps } from 'styled-components';
// TODO: Remove memoizeOne from package.json
// import memoizeOne from 'memoize-one';
import { ColumnInternalProps } from './Column.styles';

const decimalToPercentageString = (num: number) => `${num * 100}%`;

const columnNumberToPercentage: { [num: number]: string } = Array.from(Array(12)).reduce((acc, curr, index) => {
  const columnNumber = index + 1;
  acc[columnNumber] = decimalToPercentageString(columnNumber / 12);
  return acc;
}, {} as Readonly<{ [num: number]: string }>);

const widthByColumnString: Readonly<{ [key: string]: string }> = {
  'one-fifth': '20%',
  'one-quarter': '25%',
  'one-third': '33.3333%',
  'two-fifths': '40%',
  half: '50%',
  'three-fifths': '60%',
  'two-thirds': '66.6666%',
  'three-quarters': '75%',
  'four-fifths': '80%',
  full: '100%',
  one: columnNumberToPercentage[1],
  two: columnNumberToPercentage[2],
  three: columnNumberToPercentage[3],
  four: columnNumberToPercentage[4],
  five: columnNumberToPercentage[5],
  six: columnNumberToPercentage[6],
  seven: columnNumberToPercentage[7],
  eight: columnNumberToPercentage[8],
  nine: columnNumberToPercentage[9],
  ten: columnNumberToPercentage[10],
  eleven: columnNumberToPercentage[11],
  twelve: columnNumberToPercentage[12],
};

const getColumnWidthValue = (columnWidth: ColumnWidth) => {
  return typeof columnWidth === 'number' ? columnNumberToPercentage[columnWidth] : widthByColumnString[columnWidth];
};

export const getColumnGap = (theme: DefaultTheme, columnGap: ColumnGap) => {
  switch (columnGap) {
    case 'md':
      return theme.space[4];
    default:
      return theme.space[3];
  }
};

// TODO: Remove this if we're not going to use it.
/*
const getMaxMobileWidth = memoizeOne((firstBreakpoint: string, rootFontSize: number) => {
  const rawRemValue = Number.parseFloat(firstBreakpoint);
  return `${(rawRemValue * rootFontSize - 1) / 16}rem`;
});
 */

const createRule = (theme: DefaultTheme, viewportSize: ViewportSize, rules: CSSObject) => {
  let mediaQuery: string;
  switch (viewportSize) {
    case 'mobile': {
      return rules;
    }
    case 'tablet':
      mediaQuery = theme.mediaQueries.sm;
      break;
    case 'desktop':
      mediaQuery = theme.mediaQueries.md;
      break;
    case 'widescreen':
      mediaQuery = theme.mediaQueries.lg;
      break;
  }

  return mediaQuery
    ? css`
        ${mediaQuery} {
          ${rules}
        }
      `
    : null;
};

const createWidthRules = (columnWidth: ColumnWidth) => {
  return {
    flex: 'none',
    width: getColumnWidthValue(columnWidth),
  };
};

const createOffsetRules = (columnWidth: ColumnWidth) => {
  return {
    marginLeft: getColumnWidthValue(columnWidth),
  };
};

const createMediaQueryForScreen = (theme: DefaultTheme, viewportSize: ViewportSize, columnWidth?: ColumnWidth) => {
  if (!columnWidth) {
    return null;
  }
  const rules = createWidthRules(columnWidth);

  return createRule(theme, viewportSize, rules);
};

const createOffsetMediaQueryForScreen = (theme: DefaultTheme, viewportSize: ViewportSize, columnWidth?: ColumnWidth) => {
  if (!columnWidth) {
    return null;
  }
  const rules = createOffsetRules(columnWidth);

  return createRule(theme, viewportSize, rules);
};

const createBaseRules = (theme: DefaultTheme, stackOnMobile: boolean, columnWidth: ColumnWidth | undefined, isOffset: boolean) => {
  if (columnWidth) {
    const rules: CSSObject = isOffset ? createOffsetRules(columnWidth) : createWidthRules(columnWidth);

    if (stackOnMobile) {
      return css`
        ${theme.mediaQueries.sm} {
          ${rules}
        }
      `;
    } else {
      return css(rules);
    }
  }
  return null;
};

export const createColumnSizeRules = (props: StyledProps<ColumnInternalProps>) => {
  const mobileMediaQueries = createMediaQueryForScreen(props.theme, 'mobile', props.mobile);
  const mobileOffsetMediaQueries = createOffsetMediaQueryForScreen(props.theme, 'mobile', props.mobileOffset);
  const tabletMediaQueries = createMediaQueryForScreen(props.theme, 'tablet', props.tablet);
  const tabletOffsetMediaQueries = createOffsetMediaQueryForScreen(props.theme, 'tablet', props.tabletOffset);
  const desktoptMediaQueries = createMediaQueryForScreen(props.theme, 'desktop', props.desktop);
  const desktopOffsetMediaQueries = createOffsetMediaQueryForScreen(props.theme, 'desktop', props.desktopOffset);
  const widescreenMediaQueries = createMediaQueryForScreen(props.theme, 'widescreen', props.widescreen);
  const widescreenOffsetMediaQueries = createOffsetMediaQueryForScreen(props.theme, 'widescreen', props.widescreenOffset);
  const baseWidthRules = createBaseRules(props.theme, props.stackOnMobile, props.width, false);
  const baseOffsetRules = createBaseRules(props.theme, props.stackOnMobile, props.offset, true);
  return [
    baseWidthRules,
    baseOffsetRules,
    mobileMediaQueries,
    mobileOffsetMediaQueries,
    tabletMediaQueries,
    tabletOffsetMediaQueries,
    desktoptMediaQueries,
    desktopOffsetMediaQueries,
    widescreenMediaQueries,
    widescreenOffsetMediaQueries,
  ];
};
