import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { getUrlWithTrailingSlash, isExternalUrl } from '@utils/urlHelpers.util';
import { useGatsbyLinkProps } from '@hooks/useGatsbyLinkProps';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { segment } from '@static/js/joy';

const StyledButton = styled.button.attrs({ shouldForwardProp })`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
`;

interface ButtonBaseProps {
  forwardedAs?: 'button';
  href?: never;
  target?: never;
  to?: never;
  type?: 'submit';
  skipRedirectWithTelemetry?: boolean;
}
interface AnchorBaseProps {
  forwardedAs: 'a';
  href: string;
  target?: string;
  to?: never;
  type?: 'submit';
  skipRedirectWithTelemetry?: boolean;
}
interface GatsbyLinkBaseProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedAs: React.ComponentType<any>;
  href?: never;
  target?: never;
  to: string;
}

interface CTAAbstractButtonProps {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  telemetryLabel?: string;
}

interface CTADefaultButtonProps extends CTAAbstractButtonProps {
  type?: 'submit';
  skipRedirectWithTelemetry?: boolean;
}

type GatsbyLinkButtonProps = GatsbyLinkBaseProps & CTADefaultButtonProps;
export type AnchorButtonProps = AnchorBaseProps & CTADefaultButtonProps;
type ButtonProps = ButtonBaseProps & CTADefaultButtonProps;

export type AllButtonProps = AnchorButtonProps | GatsbyLinkButtonProps | ButtonProps;

const CTAButtonBase = ({
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  as,
  target,
  type,
  telemetryLabel,
  skipRedirectWithTelemetry = false,
  forwardedAs,
  ...restProps
}: PropsWithChildren<AllButtonProps & { as?: AllButtonProps['forwardedAs'] }>) => {
  let rel;
  if (as === 'a') {
    if (isExternalUrl(restProps.href)) {
      target = target || '_blank';
      if (target === '_blank') {
        rel = 'noopener noreferrer';
      }
    }
  }
  if (restProps.to) {
    restProps.to = getUrlWithTrailingSlash(restProps.to);
  }
  const handleOnClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
    if (!skipRedirectWithTelemetry) {
      segment.redirectWithTelemetry(event, undefined, telemetryLabel);
    }
  };

  const handleOnMouseEnter = (event: React.MouseEvent) => onMouseEnter && onMouseEnter(event);

  const handleOnMouseLeave = (event: React.MouseEvent) => onMouseLeave && onMouseLeave(event);

  return (
    <StyledButton
      as={as}
      className={className}
      rel={rel}
      target={target}
      aria-label={restProps.id}
      {...restProps}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnClick}
    />
  );
};

const withGatsbyLink = (Component: React.FunctionComponent<PropsWithChildren<AllButtonProps>>) => (
  props: PropsWithChildren<AllButtonProps & { as?: never }>
) => {
  const [gatsbyLinkProps] = useGatsbyLinkProps(props);
  return <Component {...gatsbyLinkProps} />;
};

const ButtonNoneStyled = styled(withGatsbyLink(CTAButtonBase))<PropsWithChildren<AllButtonProps & { as?: never }>>``;

ButtonNoneStyled.defaultProps = {
  forwardedAs: 'button',
};

ButtonNoneStyled.displayName = 'ButtonNoneStyled';

export { ButtonNoneStyled };
