import { BreakpointSize, JoykitColor, TypographyVariant } from '@components/themes';
import { MarcomStyledComponentsProps } from '@components/MarcomThemeProvider';
import { getColumnGap } from '@components/Columns';
import { columnGapsMap } from '@components/Columns/Columns.styles';

const isRGBColor = (color: string) => color.startsWith('rgb');
const isHEXColor = (color: string) => color.startsWith('#');
const HEXtoRGBA = (color: string, alpha: number) => {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha}})`;
};
const modifyRGBAlpha = (color: string, alpha: number) => {
  const [r, g, b] = color
    .replace('rgba(', '')
    .replace(')', '')
    .split(',');
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const themeVariant = (typography: TypographyVariant) => ({ theme }: MarcomStyledComponentsProps) => theme.typography[typography];
export const themeColor = (color: JoykitColor, alpha?: number) => ({ theme }: MarcomStyledComponentsProps) => {
  const selectedColor = theme.colors[color];

  if (!alpha) {
    return selectedColor;
  }

  if (isHEXColor(selectedColor)) {
    return HEXtoRGBA(selectedColor, alpha);
  }

  if (isRGBColor(selectedColor)) {
    return modifyRGBAlpha(selectedColor, alpha);
  }

  return selectedColor;
};
export const themeSpace = (space: number) => ({ theme }: MarcomStyledComponentsProps) => {
  let result = theme.space[Math.abs(space)];
  if (space < 0) {
    result = `-${result}`;
  }
  return result;
};
export const themeMediaQuery = (breakpointSize: BreakpointSize) => ({ theme }: MarcomStyledComponentsProps) =>
  theme.mediaQueries[breakpointSize];

export const themeColumnWidth = (breakpointSize: Exclude<BreakpointSize, 'below_sm' | 'tablet'>, columnsPerRow: number) => ({
  theme,
}: MarcomStyledComponentsProps) => {
  const { rootFontSize } = theme.global;
  const columnGap = columnGapsMap[breakpointSize];
  // * 2 as both left and right columns have paddings on sides
  const columnsPadding = Number.parseFloat(getColumnGap(theme, columnGap)) * rootFontSize * 2;
  const maxInnerWidth = theme.maxColumnsWidths[breakpointSize];

  // Inner gutters are not collapsed, so we'll
  return `${(maxInnerWidth - columnsPadding * (columnsPerRow - 1)) / rootFontSize / columnsPerRow}rem`;
};
