import {
  TypographyMappingDesktopXlVariant,
  TypographyMappingMobileVariant,
  TypographyMappingTabletDesktopVariant,
} from '@components/themes';

const toMobileFontsMapping: { [variant in TypographyMappingDesktopXlVariant]: TypographyMappingMobileVariant } = {
  hed120SuperEmphasis: 'hed56SuperEmphasis',
  hed120Super: 'hed40',
  hed96Break: 'hed40',
  hed72Hero: 'hed40',
  hed56: 'hed32',
  hed40: 'hed32',
  calloutHed40Bold: 'calloutHed32',
  calloutHed48: 'calloutHed24',
  calloutHed40: 'calloutHed32',
  calloutDek40: 'dek32',
  calloutHed32: 'sectionEyeBrow24',
  sectionEyeBrow24: 'sectionEyeBrow20',
  sectionEyeBrow20: 'sectionEyeBrow20',
  dek24: 'dek20',
  austinNewsDek24: 'austinNewsDek24',
  dek20: 'featureCopy17',
  dek15: 'dek15',
  featureHed20: 'featureHed20',
  featureHed17: 'featureHed17',
  featureCopy17: 'featureCopy17',
  caption: 'caption',
  legal: 'legal',
  stat: 'hed40',
  navParent15: 'navParent15',
  navParent18: 'navParent18',
  navChild36: 'navChild32',
  navSearch148: 'navSearch48',
} as const;

const toTabletFontsMapping: { [variant in TypographyMappingDesktopXlVariant]: TypographyMappingTabletDesktopVariant } = {
  hed120SuperEmphasis: 'hed96SuperEmphasis',
  hed120Super: 'hed96Break',
  hed96Break: 'hed72Hero',
  hed72Hero: 'hed56',
  hed56: 'hed40',
  hed40: 'hed32',
  calloutHed40Bold: 'calloutHed32',
  calloutHed48: 'calloutHed40',
  calloutHed40: 'calloutHed32',
  calloutDek40: 'dek32',
  calloutHed32: 'sectionEyeBrow24',
  sectionEyeBrow24: 'sectionEyeBrow20',
  sectionEyeBrow20: 'sectionEyeBrow20',
  dek24: 'dek20',
  austinNewsDek24: 'austinNewsDek24',
  dek20: 'featureCopy17',
  dek15: 'dek15',
  featureHed20: 'featureHed20',
  featureHed17: 'featureHed17',
  featureCopy17: 'featureCopy17',
  caption: 'caption',
  legal: 'legal',
  stat: 'hed56',
  navParent15: 'navParent15',
  navParent18: 'navParent18',
  navChild36: 'navChild36',
  navSearch148: 'navSearch122',
} as const;

export const getMobileFontVariant = (desktopFontVariant: TypographyMappingDesktopXlVariant) => {
  return toMobileFontsMapping[desktopFontVariant];
};

export const getTabletDesktopFontVariant = (desktopFontVariant: TypographyMappingDesktopXlVariant) => {
  return toTabletFontsMapping[desktopFontVariant];
};

type TripleBlock = Readonly<{
  eyebrowVariant: TypographyMappingDesktopXlVariant;
  titleVariant: TypographyMappingDesktopXlVariant;
  bodyVariant: TypographyMappingDesktopXlVariant;
}>;

type EyebrowTitleBlock = Pick<TripleBlock, 'eyebrowVariant' | 'titleVariant'> & {
  bodyVariant?: undefined | never;
};
type TitleBodyBlock = Pick<TripleBlock, 'titleVariant' | 'bodyVariant'> & {
  eyebrowVariant?: undefined | never;
};

type SpacingVariant = readonly [
  TripleBlock | EyebrowTitleBlock | TitleBodyBlock,
  { eyebrowSpacing?: readonly [number, number, number]; titleSpacing?: readonly [number, number, number] }
];
export type SpacingVariants = ReadonlyArray<SpacingVariant>;

const baseVariant: {
  eyebrowVariant?: undefined | never;
  bodyVariant?: undefined | never;
} = {};

//
export const spacingVariants = [
  // triple
  [
    { ...baseVariant, eyebrowVariant: 'calloutHed32', titleVariant: 'calloutHed40', bodyVariant: 'calloutDek40' },
    { eyebrowSpacing: [7, 7, 7], titleSpacing: [1, 1, 1] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'hed56', bodyVariant: 'dek24' },
    { eyebrowSpacing: [3, 3, 3], titleSpacing: [6, 6, 6] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow20', titleVariant: 'hed56', bodyVariant: 'dek24' },
    { eyebrowSpacing: [3, 3, 3], titleSpacing: [6, 6, 6] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow20', titleVariant: 'hed56', bodyVariant: 'dek20' },
    { eyebrowSpacing: [3, 3, 3], titleSpacing: [5, 5, 5] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow20', titleVariant: 'hed40', bodyVariant: 'dek20' },
    { eyebrowSpacing: [3, 3, 3], titleSpacing: [6, 6, 6] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'calloutHed32', titleVariant: 'hed72Hero', bodyVariant: 'dek24' },
    { eyebrowSpacing: [5, 5, 5], titleSpacing: [7, 7, 7] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'hed120Super', bodyVariant: 'dek24' },
    { eyebrowSpacing: [6, 6, 6], titleSpacing: [10, 10, 10] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'hed120SuperEmphasis', bodyVariant: 'dek24' },
    { eyebrowSpacing: [6, 6, 6], titleSpacing: [10, 10, 10] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow20', titleVariant: 'hed56', bodyVariant: 'featureCopy17' },
    { eyebrowSpacing: [6, 6, 6], titleSpacing: [5, 5, 5] },
  ],
  //
  [
    {
      ...baseVariant,
      eyebrowVariant: 'calloutHed32',
      titleVariant: 'hed120SuperEmphasis',
      bodyVariant: 'dek24',
    },
    { eyebrowSpacing: [7, 7, 7], titleSpacing: [10, 10, 10] },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'calloutHed32',
      titleVariant: 'hed120Super',
      bodyVariant: 'dek24',
    },
    { eyebrowSpacing: [7, 7, 7], titleSpacing: [10, 10, 10] },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'calloutHed32',
      titleVariant: 'hed96Break',
      bodyVariant: 'dek24',
    },
    { eyebrowSpacing: [7, 7, 7], titleSpacing: [10, 10, 10] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'hed96Break', bodyVariant: 'dek24' },
    { eyebrowSpacing: [6, 6, 6], titleSpacing: [10, 10, 10] },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow24',
      titleVariant: 'hed96Break',
      bodyVariant: 'dek20',
    },
    { eyebrowSpacing: [7, 7, 7], titleSpacing: [10, 10, 10] },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow24',
      titleVariant: 'hed72Hero',
      bodyVariant: 'dek24',
    },
    { eyebrowSpacing: [7, 7, 7], titleSpacing: [7, 7, 7] },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow24',
      titleVariant: 'hed72Hero',
      bodyVariant: 'dek20',
    },
    { eyebrowSpacing: [3, 3, 3], titleSpacing: [6, 6, 6] },
  ],
  [
    { ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'hed56', bodyVariant: 'dek20' },
    { eyebrowSpacing: [5, 5, 5], titleSpacing: [9, 9, 9] },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow24',
      titleVariant: 'hed40',
      bodyVariant: 'dek20',
    },
    { eyebrowSpacing: [5, 5, 5], titleSpacing: [7, 7, 7] },
  ],
  // eyebrow-title
  [{ ...baseVariant, eyebrowVariant: 'calloutHed32', titleVariant: 'hed120Super' }, { eyebrowSpacing: [7, 7, 7] }],
  [{ ...baseVariant, eyebrowVariant: 'calloutHed32', titleVariant: 'hed96Break' }, { eyebrowSpacing: [7, 7, 7] }],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow24',
      titleVariant: 'hed96Break',
    },
    {
      eyebrowSpacing: [5, 5, 5],
    },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow24',
      titleVariant: 'hed72Hero',
    },
    {
      eyebrowSpacing: [5, 5, 5],
    },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow24',
      titleVariant: 'hed56',
    },
    {
      eyebrowSpacing: [5, 5, 5],
    },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow20',
      titleVariant: 'hed40',
    },
    {
      eyebrowSpacing: [5, 5, 5],
    },
  ],
  [
    {
      ...baseVariant,
      eyebrowVariant: 'sectionEyeBrow20',
      titleVariant: 'hed56',
    },
    {
      eyebrowSpacing: [5, 5, 5],
    },
  ],
  [{ ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'calloutHed40Bold' }, { eyebrowSpacing: [5, 5, 5] }],
  [{ ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'calloutHed40' }, { eyebrowSpacing: [5, 5, 5] }],
  [{ ...baseVariant, eyebrowVariant: 'sectionEyeBrow24', titleVariant: 'dek24' }, { eyebrowSpacing: [5, 5, 5] }],
  [{ ...baseVariant, eyebrowVariant: 'featureHed17', titleVariant: 'hed40', bodyVariant: 'dek20' }, { eyebrowSpacing: [5, 3, 3] }],
  // title-body
  [{ ...baseVariant, titleVariant: 'calloutHed32', bodyVariant: 'dek20' }, { titleSpacing: [5, 5, 5] }],
  [{ ...baseVariant, titleVariant: 'calloutHed32', bodyVariant: 'dek24' }, { titleSpacing: [5, 5, 5] }],
  [{ ...baseVariant, titleVariant: 'calloutHed32', bodyVariant: 'featureCopy17' }, { titleSpacing: [5, 5, 5] }],
  [{ ...baseVariant, titleVariant: 'calloutHed40', bodyVariant: 'dek24' }, { titleSpacing: [5, 5, 5] }],
  [{ ...baseVariant, titleVariant: 'calloutHed40Bold', bodyVariant: 'dek24' }, { titleSpacing: [3, 3, 3] }],
  [{ ...baseVariant, titleVariant: 'calloutHed40Bold', bodyVariant: 'dek20' }, { titleSpacing: [3, 3, 3] }],
  [{ ...baseVariant, titleVariant: 'dek24', bodyVariant: 'featureCopy17' }, { titleSpacing: [3, 3, 3] }],
  [{ ...baseVariant, titleVariant: 'featureHed20', bodyVariant: 'dek24' }, { titleSpacing: [5, 5, 5] }],
  [{ ...baseVariant, titleVariant: 'featureHed17', bodyVariant: 'featureCopy17' }, { titleSpacing: [4, 4, 4] }],
  [{ ...baseVariant, titleVariant: 'hed120Super', bodyVariant: 'dek24' }, { titleSpacing: [7, 7, 7] }],
  [{ ...baseVariant, titleVariant: 'hed96Break', bodyVariant: 'calloutDek40' }, { titleSpacing: [8, 8, 8] }],
  [{ ...baseVariant, titleVariant: 'hed96Break', bodyVariant: 'dek24' }, { titleSpacing: [7, 7, 7] }],
  [{ ...baseVariant, titleVariant: 'hed56', bodyVariant: 'dek20' }, { titleSpacing: [6, 6, 6] }],
  [{ ...baseVariant, titleVariant: 'hed72Hero', bodyVariant: 'dek20' }, { titleSpacing: [7, 7, 7] }],
  [{ ...baseVariant, titleVariant: 'hed72Hero', bodyVariant: 'dek24' }, { titleSpacing: [7, 7, 7] }],
  [{ ...baseVariant, titleVariant: 'sectionEyeBrow24', bodyVariant: 'dek24' }, { titleSpacing: [5, 5, 5] }],
  [{ ...baseVariant, titleVariant: 'sectionEyeBrow24', bodyVariant: 'dek20' }, { titleSpacing: [2, 2, 2] }],
  [{ ...baseVariant, titleVariant: 'hed120SuperEmphasis', bodyVariant: 'dek24' }, { titleSpacing: [7, 7, 7] }],
  [{ ...baseVariant, titleVariant: 'hed40', bodyVariant: 'featureCopy17' }, { titleSpacing: [6, 6, 6] }],
  [{ ...baseVariant, titleVariant: 'hed40', bodyVariant: 'dek20' }, { titleSpacing: [6, 6, 6] }],
  [{ ...baseVariant, titleVariant: 'calloutDek40', bodyVariant: 'featureHed17' }, { titleSpacing: [7, 7, 7] }],
  [{ ...baseVariant, titleVariant: 'sectionEyeBrow20', bodyVariant: 'featureCopy17' }, { titleSpacing: [5, 5, 5] }],
  //unique text pairings
  [
    {
      ...baseVariant,
      eyebrowVariant: 'hed96Break',
      titleVariant: 'calloutHed32',
      bodyVariant: 'dek24',
    },
    { eyebrowSpacing: [1, 1, 1], titleSpacing: [9, 9, 9] },
  ],
] as const;

export type LimitedSpacingVariants = typeof spacingVariants;
export type TextBlockVariant = typeof spacingVariants[number][0];

// TODO: Unused variable might want to remove.
const spacingVariantsTypeChecker: SpacingVariants = spacingVariants;

const defaultSpacing = {
  eyebrowSpacing: [3, 4, 4],
  titleSpacing: [5, 6, 6],
} as const;

export const findSpacingVariant = ({
  eyebrowVariant,
  titleVariant,
  bodyVariant,
}: {
  eyebrowVariant?: TypographyMappingDesktopXlVariant;
  titleVariant: TypographyMappingDesktopXlVariant;
  bodyVariant?: TypographyMappingDesktopXlVariant;
}): SpacingVariant[1] => {
  const foundVariant: SpacingVariant | undefined = spacingVariants.find((variant: SpacingVariant) => {
    const isSameTitle = variant[0].titleVariant === titleVariant;
    const isSameEyebrow = variant[0].eyebrowVariant === (eyebrowVariant || undefined);
    const isSameBody = variant[0].bodyVariant === (bodyVariant || undefined);
    return isSameEyebrow && isSameTitle && isSameBody;
  });
  const titleSpacing = (foundVariant && foundVariant[1].titleSpacing) || defaultSpacing.titleSpacing;
  const eyebrowSpacing = (foundVariant && foundVariant[1].eyebrowSpacing) || defaultSpacing.eyebrowSpacing;
  // TODO: no-op, might want to remove.
  if (!foundVariant) {
    // console.debug('Not found typography variant:', `${eyebrowVariant} / ${titleVariant} / ${bodyVariant}`);
  }
  return { titleSpacing, eyebrowSpacing };
};
