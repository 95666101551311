import React from 'react';
import { Text } from '@components/typography';
import { JoykitIcon } from '@components/JoykitIcon';
import { StyledNavMenuLink } from './Header.styles';
import { HeaderMobileNavMenuLinkProps } from './Header.types';
import { headerWrapExperiment } from './HeaderWrapExperiment';

const HeaderMobileNavMenuLink: React.FC<HeaderMobileNavMenuLinkProps> = ({
  idx,
  idxParent,
  hasBlogs,
  id,
  title,
  handleOpenBlogMenu,
  to,
  href,
  target,
  experimentKey,
  isVisible,
}) => {
  const link = href ? href : to ? to : null;
  const Wrapper = headerWrapExperiment(experimentKey);
  return link ? (
    <Wrapper key={id}>
      <StyledNavMenuLink
        id={'mobile-' + id}
        href={link}
        target={target}
        variant="navLink"
        onClick={event => {
          if (hasBlogs) {
            event.preventDefault();
            handleOpenBlogMenu(idxParent, idx);
          }
        }}
        isVisible={isVisible}
      >
        <Text variant="navChild36" tagName="span">
          {title}
        </Text>
        {hasBlogs && <JoykitIcon name="forwardChevron" color="mono1" size={24} marginTop={2} />}
      </StyledNavMenuLink>
    </Wrapper>
  ) : null;
};

HeaderMobileNavMenuLink.displayName = 'HeaderMobileNavMenuLink';

export default HeaderMobileNavMenuLink;
