import { Flex } from '@components/Flex';
import { styled } from '@styledComponents';
import { Button } from '@components/Button';
import { Text } from '@components/typography';
import { TOP_BANNER_HEIGHT } from 'src/layout/Layout.constants';
import { Label2, Body2 } from '@components/typography/interVariants';
import { themeColor, themeSpace, themeMediaQuery } from '@utils/styledTheme.util';

export const DesktopWrapper = styled(Flex)`
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const StyledBannerContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: ${TOP_BANNER_HEIGHT}px;
  position: relative;
  top: 0;
  animation: topbar;
  animation-delay: 0.6s;
  animation-duration: 4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  background: ${themeColor('black', 0.8)};
  backdrop-filter: blur(8px) saturate(180%);
`;

export const StyledNavButton = styled(Button)<{ active: boolean }>`
  ${Label2};
  height: ${TOP_BANNER_HEIGHT}px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 0px;
  padding: 0 ${themeSpace(5)};
  text-underline-offset: 3px;
  background: transparent;
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  &:hover {
    background: transparent;
    text-decoration: underline;
  }
  &:active {
    background: transparent;
    color: ${themeColor('white')};
  }
`;

export const StyledFlex = styled(Flex)`
  position: relative;
  column-gap: ${themeSpace(1)};
`;

export const StyledNewFlag = styled(Flex)`
  column-gap: ${themeSpace(1)};
`;

export const StyledText = styled(Text)`
  ${Body2};
  padding: ${themeSpace(1)} ${themeSpace(2)};
  background-color: ${themeColor('white')};
  border-radius: ${themeSpace(7)};
`;
