import queryString from 'query-string';

export type DexecureOptimizationMode = 'mild' | 'aggressive' | 'default' | 'none' | undefined;

interface DexecureQueryStringOptions {
  resize?: string;
  opt?: DexecureOptimizationMode;
}

export interface DexecureImageOptions {
  /**
   * Width of the box to fit new image into.
   * Aspect ratio is always maintained. Image is resized such that it fits into a box of the given width and height dimensions.
   */
  resizeWidth?: number;
  /**
   * Height of the box to fit new image into.
   * Aspect ratio is always maintained. Image is resized such that it fits into a box of the given width and height dimensions.
   */
  resizeHeight?: number;
  /**
   * Resize width to use for mobile screen sizes.
   */
  mobileResizeWidth?: number;
  /**
   * Resize height to use for mobile screen sizes.
   */
  mobileResizeHeight?: number;
  /**
   * <not provided> or `default` - When no optimizationMode is provided, Dexecure will perform all optimisations on your assets. This results in assets to be the ideal quality required for each device type. This mode can also be requested by `default`
   *
   * `mild` - This mode performs only lossless optimisations. i.e. the optimised image will have no difference in quality compared to the original. Attributes like metadata, ICC profile are removed. Auto resizing is also not done. Format conversion is also not done.
   *
   * `aggressive` - Performs more aggressive optimisations to significantly reduce file sizes.
   *
   * `none` - Use this mode to disable all optimisations performed by Dexecure. This will also disable auto resizing. It will just return the original image. Note that resize can still be done by providing resize parameter.
   */
  optimization?: DexecureOptimizationMode;
  /**
   * Dexecure by default does automatic resizing of images based on the viewing device type, so that mobile devices do not need to download and display oversized images.
   */
  isResizeDisabled?: boolean;
  /**
   * For now added the options that we'll use for sure. If some custom options needed please check https://support.dexecure.com/hc/url-configuration
   */
}
interface DexecureVideoOptions {
  resizeWidth?: never;
  resizeHeight?: never;
  mobileResizeWidth?: never;
  mobileResizeHeight?: never;
  optimization?: never;
  isResizeDisabled?: never;
}
// increment this value if we end up replacing some assets in place
const cacheBuster = 2;

// On mobile retina displays, request higher resolution images by default, otherwise images will look blurry
// I seen all mobile images mostly use 600 and 450 is conflict with initial load images for mobile.
const DEFAULT_MOBILE_RESIZE_WIDTH_PX = 600;
const MOBILE_DEVICE_WIDTH_PX = 450;

export const dexecureSettings = {
  baseCloudUrl: 'https://withjoy.com/assets/public/marcom-prod/',
  cacheBuster: `${cacheBuster}`,
  defaultOptimation: 'default',
} as const;

const urlTester = (() => {
  if (typeof window === 'undefined' || !window.location.search.includes('feature.testurls=true')) {
    return () => {};
  }

  const cloudUrls = new Set<string>();

  return (url: string) => {
    if (cloudUrls.has(url)) {
      return;
    }
    cloudUrls.add(url);

    fetch(url, {
      method: 'HEAD',
    }).then(response => {
      if (response.status !== 200) {
        console.error('TESTURLS', url);
      }
    });
  };
})();

/**
 * Get full asset url from cloud(currently s3 + dexecure)
 * @param relativePath - path relative to *marcom* folder
 * @param options - dexecure configuration options
 */
export const cloudAssetUrl = (relativePath?: string, options?: DexecureImageOptions | DexecureVideoOptions) => {
  relativePath = relativePath || '';

  if (relativePath.startsWith('https://') || relativePath.startsWith('//')) {
    throw new Error('All assets paths should be relative so they can be sourced via the CDN and dexecure for optimization');
  }

  let defaultMobileResizeWidth: number | undefined = DEFAULT_MOBILE_RESIZE_WIDTH_PX;

  if (typeof window !== 'undefined' && window.innerWidth > MOBILE_DEVICE_WIDTH_PX) {
    defaultMobileResizeWidth = undefined;
  }

  const parsedUrl = queryString.parseUrl(dexecureSettings.baseCloudUrl + relativePath);
  const parsedQuery = parsedUrl.query;

  parsedQuery.ver = dexecureSettings.cacheBuster;

  if (options) {
    if (options.isResizeDisabled) {
      parsedQuery.resize = 'none';
    } else {
      const resizeOptions = [];
      if (options.resizeWidth) {
        resizeOptions.push('w' + options.resizeWidth);
      }

      if (options.resizeHeight) {
        resizeOptions.push('h' + options.resizeHeight);
      }

      const mobileResizeOptions = [];
      if (options.mobileResizeWidth) {
        mobileResizeOptions.push('w' + options.mobileResizeWidth);
      } else if (defaultMobileResizeWidth) {
        mobileResizeOptions.push('w' + defaultMobileResizeWidth);
      }

      if (options.resizeHeight) {
        mobileResizeOptions.push('h' + options.mobileResizeHeight);
      }

      // only desktop options.
      if (resizeOptions.length) {
        parsedQuery.resize = resizeOptions.join(',');
      }

      // only mobile options.
      if (mobileResizeOptions.length) {
        parsedQuery.m_resize = mobileResizeOptions.join(',');
      }
    }

    if (options.optimization) {
      parsedQuery.opt = options.optimization;
    }
  }

  if (!parsedQuery.opt && dexecureSettings.defaultOptimation) {
    parsedQuery.opt = dexecureSettings.defaultOptimation;
  }

  const retVal = queryString.stringifyUrl(parsedUrl, parsedQuery);
  urlTester(retVal);

  return retVal;
};
