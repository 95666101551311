import { JoykitIconVariant } from '@components/JoykitIcon';

export type navType = { icon: JoykitIconVariant; name: string; path?: string; id: string };

export const NavBarData: navType[] = [
  { icon: 'weddingWebsite', name: 'Website', path: '/wedding-website/', id: 'mobFeatureNav2WeddingWebsite' },
  { icon: 'registryIcon', name: 'Registry', path: '/registry/', id: 'mobFeatureNav2Registry' },
  { icon: 'guestList', name: 'Guest List', path: '/guest-list/', id: 'mobFeatureNav2GuestList' },
  { icon: 'saveTheDate', name: 'Save the Dates', path: '/save-the-date/', id: 'mobFeatureNav2SaveTheDates' },
  { icon: 'invite', name: 'Invitations', path: '/wedding-invitations/', id: 'mobFeatureNav2Invitations' },
];
