import queryString from 'query-string';

interface LazyLoadDefaultProps
  extends Readonly<{
    defaultLazyLoadThreshold: number;
    isImagesEagerByDefault: boolean;
  }> {}

export const LazyLoadDefaultProps = (() => {
  let threshold;
  let isImagesEagerByDefault;
  if (typeof window === 'object') {
    const searchStr = (window.location.search || '').toLowerCase();
    const searchVo = queryString.parse(searchStr);
    threshold = Number(searchVo['feature.lazyloadthreshold']);
    isImagesEagerByDefault = searchVo['feature.imageseagerbydefault'] === 'true';
  }
  return { defaultLazyLoadThreshold: threshold || 500, isImagesEagerByDefault: isImagesEagerByDefault || false };
})();
