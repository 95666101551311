import { useState, useEffect } from 'react';

export default function useLongPress(callback: () => void, delay: number = 1500) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined | number;
    if (startLongPress) {
      timeout = setTimeout(callback, delay);
    } else {
      clearTimeout(timeout as NodeJS.Timeout);
    }

    return () => {
      clearTimeout(timeout as NodeJS.Timeout);
    };
  }, [callback, delay, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
    onContextMenu: (event: React.MouseEvent) => event.preventDefault(),
  };
}
