import { styled } from '@styledComponents';
import { Box } from '@components/Box';
import { Flex } from '@components/Flex';
import { Button } from '@components/Button';
import { Text } from '@components/typography';
import { Columns } from '@components/Columns';
import { CTALink } from '@components/CTALink';
import { ParticleJoyLogo } from 'src/layout/ParticleJoyLogo';
import { animationTransition } from '@utils/animationTransition';
import { Label2, SmallTextStyles } from '@components/typography/interVariants';
import { GetStartedButton } from '@components/GetStartedButton/GetStartedButton';
import { themeColor, themeMediaQuery, themeSpace, themeVariant } from '@utils/styledTheme.util';
import LetterSlider from '@components/LetterSlider/LetterSlider';

const DESKTOP_BREAKPOINT = '1024px';
const LETTER_SLIDER_MIN_WIDTH = {
  default: '390px',
  belowSm: '170px',
};

export const StyledFooter = styled.footer`
  z-index: 1;
  position: relative;
`;

export const FooterMenuContainer = styled(Box)`
  background-color: white;
  padding-bottom: ${themeSpace(8)};
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    background-color: #f7f7f7;
    padding-bottom: 122px;
  }
`;

export const FooterColumnContainer = styled(Box)`
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    max-width: 100%;
  }
`;

export const FooterColumns = styled(Columns)`
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    flex-direction: row;
  }
`;

export const FooterColumn = styled(Columns.Column)`
  width: 100%;
  text-align: left;
  display: flex;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    width: 50%;
    display: block;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const FooterColumnBottom = styled(FooterColumn)`
  width: 100%;
`;

export const FooterMenuWrapper = styled(Flex)`
  row-gap: ${themeSpace(3)};
  padding-top: ${themeSpace(6)};
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    row-gap: ${themeSpace(4)};
    padding-top: 106px;
  }
`;

export const FooterSubMenuContainer = styled(Flex)`
  justify-content: flex-start;
  margin-top: ${themeSpace(6)};
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    justify-content: flex-end;
    margin-top: 120px;
  }
`;

export const FooterSubMenuWrapper = styled(Flex)`
  width: 410px;
  row-gap: ${themeSpace(3)};
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    row-gap: ${themeSpace(6)};
  }
`;

export const FooterMenuCTALink = styled(CTALink)`
  font-size: 32px;
  line-height: 64px;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    font-size: 48px;
    line-height: 64px;
  }
  ${themeMediaQuery('md')} {
    font-size: 72px;
    line-height: 88px;
  }
`;

export const FooterSubMenuCTALink = styled(CTALink)`
  font-size: 20px;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    font-size: 24px;
  }
`;

export const UniversalFooterWrapper = styled(Box)`
  z-index: 1;
  position: relative;
`;

export const UniversalFooter = styled(Flex)`
  width: 100%;
  margin: auto;
  max-width: 450px;
  row-gap: ${themeSpace(10)};
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    max-width: 100%;
    row-gap: calc(${themeSpace(9)} + ${themeSpace(3)});
  }
`;

export const UniversalFooterContainer = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  row-gap: ${themeSpace(10)};
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    row-gap: ${themeSpace(6)};
    flex-direction: row;
    alignitems: center;
  }
`;

export const UniversalCtaContainer = styled(Flex)`
  width: 100%;
  row-gap: ${themeSpace(3)};
  flex-direction: column;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    width: initial;
    column-gap: ${themeSpace(4)};
    flex-direction: row;
  }
`;

export const UniversalFooterLink = styled(CTALink)`
  line-height: 18px;
  column-gap: ${themeSpace(3)};
  color: ${themeColor('white')};
  &:hover {
    color: ${themeColor('mono3')};
  }
  &:active {
    color: ${themeColor('white')};
  }
  svg {
    color: ${themeColor('white')};
    &:hover {
      color: ${themeColor('mono3')};
    }
    &:active {
      color: ${themeColor('white')};
    }
    transition: ${animationTransition('color')};
  }
`;

export const NewFlagText = styled(Text)`
  ${Label2};
  font-weight: 600;
  padding: 0 ${themeSpace(2)};
  background-color: ${themeColor('white')};
  border-radius: ${themeSpace(1)};
`;

export const UniversalFooterMenuWrapper = styled(Flex)`
  flex-direction: column;
  row-gap: ${themeSpace(6)};
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    flex-direction: row;
    column-gap: ${themeSpace(6)};
  }
`;

export const StyledGetStartedButton = styled(GetStartedButton)`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${themeSpace(9)};
  justify-content: center;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    width: 174px;
    height: auto;
    color: ${themeColor('mono1')};
    background-color: ${themeColor('white')};
  }
`;

export const StyledButton = styled(Button)<{ isLogin: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${themeSpace(9)};
  justify-content: center;
  border: ${({ isLogin }) => (isLogin ? '2px solid white' : 'transperant')};
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    width: 174px;
    height: auto;
    border: none;
  }
`;

export const FooterCopyrightContainer = styled(UniversalFooterContainer)`
  ${themeMediaQuery('sm')} {
    box-shadow: 0px -1px 0px 0px ${themeColor('mono12')};
  }
`;

export const CopyrightText = styled(Text)`
  ${SmallTextStyles};
  color: ${themeColor('white')};
`;

export const StyledFooterLink = styled(UniversalFooterLink)<{ noreferrer?: boolean; noopener?: boolean }>`
  ${SmallTextStyles};
`;

export const FooterMenuDisplayAboveMobile = styled(UniversalFooterMenuWrapper)`
  display: none;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    display: flex;
  }
`;

export const FooterMenuDisplayOnMobile = styled(UniversalFooterMenuWrapper)`
  display: flex;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;

export const UniversalFooterSocialMenuWrapper = styled(UniversalFooterMenuWrapper)`
  flex-direction: row;
  column-gap: ${themeSpace(6)};
`;

export const UniversalFooterLogoContainer = styled(Flex)`
  gap: ${themeSpace(10)} calc(${themeSpace(5)} + ${themeSpace(4)});
  flex-direction: column;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    flex-direction: row;
  }
`;

export const StyledParticleJoyLogo = styled(ParticleJoyLogo)`
  justify-content: center;
  svg,
  canvas {
    width: 89px;
    height: initial;
  }
  ${themeMediaQuery('below_sm')} {
    svg,
    canvas {
      width: 51.28px;
      height: ${themeSpace(7)};
    }
  }
`;

export const TradeMarkContainer = styled(Flex)`
  justify-content: center;
  align-items: flex-start;
  background-color: ${themeColor('neutra6')};
  padding: 43px 0;
  gap: ${themeSpace(4)};
  ${themeMediaQuery('below_sm')} {
    align-items: center;
    gap: ${themeSpace(3)};
    padding: ${themeSpace(7)} 0;
  }
`;

export const ContentWrapper = styled(Flex)`
  gap: ${themeSpace(3)};
  ${themeMediaQuery('below_sm')} {
    gap: ${themeSpace(2)};
  }
`;

export const TradeMarkText = styled(Box)`
  color: white;
  ${themeVariant('calloutHed40')}
  ${themeMediaQuery('below_sm')} {
    ${themeVariant('sectionEyeBrow20')}
  }
`;

export const WithJoyText = styled(Box)`
  color: white;
  ${themeVariant('calloutHed40')}
  font-family: 'Austin News Deck Web';
  font-weight: 500;
  font-style: italic;
  ${themeMediaQuery('below_sm')} {
    ${themeVariant('sectionEyeBrow20')}
    font-family: 'Austin News Deck Web';
    font-style: italic;
    font-weight: 500;
  }
`;

export const StyledLetterSlider = styled(LetterSlider)`
  min-width: ${LETTER_SLIDER_MIN_WIDTH.default};
  text-align: end;
  ${themeMediaQuery('below_sm')} {
    min-width: ${LETTER_SLIDER_MIN_WIDTH.belowSm};
  }
`;
