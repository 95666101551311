declare const window: Window;

type ColorType = {
  r: number;
  g: number;
  b: number;
  a?: number;
};

export interface ParticleEffectConfig {
  particleNumber: number;
  maxParticleSize: number;
  maxSpeed: number;
  colorVariation: number;
}

export type ParticleEffectManagerType = {
  cleanUpArray: () => void;
  initParticles: (numParticles: number, x: number, y: number) => void;
  removeParticles: (particleNumber?: number) => void;
  changeBgColor: (color: string) => void;
};

export const particleEffect = (
  canvas: HTMLCanvasElement | null,
  bgColor: string,
  config: ParticleEffectConfig = {
    particleNumber: 10,
    maxParticleSize: 10,
    maxSpeed: 5,
    colorVariation: 5,
  }
): ParticleEffectManagerType | null => {
  if (canvas) {
    // Little Canvas things
    const ctx = canvas.getContext('2d');

    // Colors
    const colorPalette = {
      bg: bgColor || { r: 32, g: 0, b: 65 },
      matter: [
        { r: 95, g: 38, b: 152 }, // Purple
        { r: 122, g: 152, b: 255 }, // Blue
        { r: 0, g: 146, b: 137 }, // Green
        { r: 255, g: 168, b: 66 }, // Yellow
        { r: 255, g: 100, b: 89 }, // Red
      ],
    };

    // Particle Constructor
    class Particle {
      public x: number = 0;
      public y: number = 0;
      public r: number = 0;
      public c: string = '';
      public s: number = 0;
      public d: number = 0;

      constructor(x: number, y: number) {
        if (canvas) {
          // X Coordinate
          this.x = x || Math.round(Math.random() * canvas.width);
          // Y Coordinate
          this.y = y || Math.round(Math.random() * canvas.height);
          // Radius of the space dust
          this.r = Math.ceil(Math.random() * config.maxParticleSize);
          // Color of the rock, given some randomness
          this.c = colorVariation(colorPalette.matter[Math.floor(Math.random() * colorPalette.matter.length)]);
          // Speed of which the rock travels
          this.s = Math.pow(Math.ceil(Math.random() * config.maxSpeed), 0.7);
          // Direction the Rock flies
          this.d = Math.round(Math.random() * 360);
        }
      }
    }

    // Some Variables hanging out
    let particles: Particle[] = [];
    const drawBg = function(ctx: CanvasRenderingContext2D, color: string | ColorType) {
      ctx.fillStyle = typeof color === 'string' ? color : 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    // Provides some nice color variation
    // Accepts an rgba object
    // returns a modified rgba object or a rgba string if true is passed in for argument 2
    const colorVariation = function(color: ColorType) {
      var r, g, b, a, variation;
      r = Math.round(Math.random() * config.colorVariation - config.colorVariation / 2 + color.r);
      g = Math.round(Math.random() * config.colorVariation - config.colorVariation / 2 + color.g);
      b = Math.round(Math.random() * config.colorVariation - config.colorVariation / 2 + color.b);
      a = Math.random() + 0.5;
      return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
    };

    // Used to find the rocks next point in space, accounting for speed and direction
    var updateParticleModel = function(p: Particle) {
      var a = 180 - (p.d + 90); // find the 3rd angle
      p.d > 0 && p.d < 180 ? (p.x += (p.s * Math.sin(p.d)) / Math.sin(p.s)) : (p.x -= (p.s * Math.sin(p.d)) / Math.sin(p.s));
      p.d > 90 && p.d < 270 ? (p.y += (p.s * Math.sin(a)) / Math.sin(p.s)) : (p.y -= (p.s * Math.sin(a)) / Math.sin(p.s));
      return p;
    };

    // Just the function that physically draws the particles
    // Physically? sure why not, physically.
    var drawParticle = function(x: number, y: number, r: number, c: string) {
      if (ctx) {
        ctx.beginPath();
        ctx.fillStyle = c;
        ctx.arc(x, y, r, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.closePath();
      }
    };

    // Remove particles that aren't on the canvas
    const cleanUpArray = function() {
      particles = particles.filter((p: Particle) => !(p.x < -50 || p.x > 100 || p.y < -50 || p.y > 100));
    };

    const requestAnimFrame = (function() {
      return (
        window.requestAnimationFrame ||
        function(callback: () => void) {
          return window.setTimeout(callback, 1000 / 60);
        }
      );
    })();

    // Our Frame function
    var frame = () => {
      if (ctx && particles.length) {
        // Draw background first
        drawBg(ctx, colorPalette.bg);
        // Update Particle models to new position
        particles.map(p => {
          return updateParticleModel(p);
        });
        cleanUpArray();
        // Draw em'
        particles.forEach(p => {
          drawParticle(p.x, p.y, p.r, p.c);
        });
        // Play the same song? Ok!
        return requestAnimFrame(frame);
      }
    };

    frame();

    const initParticles = function(numParticles: number, x: number, y: number) {
      const isFrameExist = !!particles.length;
      particles = [];
      for (let i = 0; i < numParticles; i++) {
        particles.push(new Particle(x, y));
      }
      particles.forEach((p: Particle) => {
        drawParticle(p.x, p.y, p.r, p.c);
      });
      if (!isFrameExist) {
        frame();
      }
    };

    const removeParticles = () => {
      particles = [];
    };

    const changeBgColor = (color: string) => {
      if (ctx) {
        drawBg(ctx, color);
      }
    };

    return { cleanUpArray, initParticles, removeParticles, changeBgColor };
  }

  return null;
};
