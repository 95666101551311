import { JoykitIconSize } from '@components/themes/createTheme.util';
import { readonlyStrEnum } from '@utils/readonlyStrEnum';

import JoyKitStoryIcon from '@images/joykit-icons/story.svg';
import JoyKitIconSchedule from '@images/joykit-icons/schedule.svg';
import JoyKitIconQAndA from '@images/joykit-icons/question-and-answer.svg';
import JoyKitIconTravel from '@images/joykit-icons/travel.svg';
import JoyKitRegistryIcon from '@images/joykit-icons/registry.svg';
import JoyKitWeddingPartyIcon from '@images/joykit-icons/wedding-party.svg';

const iconSvgVariants = ['story', 'schedule', 'questionAndAnswer', 'travel', 'registry', 'weddingParty'] as const;
const IconSvgVariantsEnum = readonlyStrEnum(iconSvgVariants);
export type JoykitIconSvgVariant = keyof typeof IconSvgVariantsEnum;

interface IconDefinition
  extends Readonly<{
    a11yLabel: string;
    defaultSize: JoykitIconSize;
    svg: string;
  }> {}

const JoykitIconsSvgDictionary: Readonly<{ [key in JoykitIconSvgVariant]: IconDefinition }> = {
  story: { a11yLabel: 'Story', svg: JoyKitStoryIcon, defaultSize: 48 },
  schedule: { a11yLabel: 'Schedule', svg: JoyKitIconSchedule, defaultSize: 48 },
  questionAndAnswer: { a11yLabel: 'Question & Answer', svg: JoyKitIconQAndA, defaultSize: 48 },
  travel: { a11yLabel: 'Travel', svg: JoyKitIconTravel, defaultSize: 48 },
  registry: { a11yLabel: 'Registry', svg: JoyKitRegistryIcon, defaultSize: 48 },
  weddingParty: { a11yLabel: 'Wedding Party', svg: JoyKitWeddingPartyIcon, defaultSize: 48 },
};

export const getIconSvgDefinition = (iconType: JoykitIconSvgVariant) => {
  return JoykitIconsSvgDictionary[iconType];
};
