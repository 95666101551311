import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ButtonVariant, StyledButton } from './Button.styles';
import { getUrlWithTrailingSlash, isExternalUrl } from '@utils/urlHelpers.util';
import { useGatsbyLinkProps } from '@hooks/useGatsbyLinkProps';

const { segment } = require('../../../static/js/joy');

interface ButtonBaseProps {
  forwardedAs?: 'button';
  href?: never;
  target?: never;
  to?: never;
  type?: 'submit';
  skipRedirectWithTelemetry?: boolean;
}
interface AnchorBaseProps {
  forwardedAs: 'a';
  href: string;
  target?: string;
  to?: never;
  type?: 'submit';
  skipRedirectWithTelemetry?: boolean;
}
interface GatsbyLinkBaseProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedAs: React.ComponentType<any>;
  href?: never;
  target?: never;
  to: string;
}

interface CTAAbstractButtonProps {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
  telemetryLabel?: string;
}

interface CTADefaultButtonProps extends CTAAbstractButtonProps {
  variant?: ButtonVariant;
  type?: 'submit';
  skipRedirectWithTelemetry?: boolean;
}

type GatsbyLinkButtonProps = GatsbyLinkBaseProps & CTADefaultButtonProps;
export type AnchorButtonProps = AnchorBaseProps & CTADefaultButtonProps;
type ButtonProps = ButtonBaseProps & CTADefaultButtonProps;

export type AllButtonProps = AnchorButtonProps | GatsbyLinkButtonProps | ButtonProps;

const CTAButtonBase = ({
  className,
  onClick,
  as,
  target,
  type,
  telemetryLabel,
  skipRedirectWithTelemetry = false,
  forwardedAs,
  ...restProps
}: PropsWithChildren<AllButtonProps & { as?: AllButtonProps['forwardedAs'] }>) => {
  let rel;
  if (as === 'a') {
    if (isExternalUrl(restProps.href)) {
      target = target || '_blank';
      if (target === '_blank') {
        rel = 'noopener noreferrer';
      }
    }
  }
  if (restProps.to) {
    restProps.to = getUrlWithTrailingSlash(restProps.to);
  }
  const handleOnClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
    if (!skipRedirectWithTelemetry) {
      segment.redirectWithTelemetry(event, undefined, telemetryLabel);
    }
  };
  return <StyledButton as={as} className={className} rel={rel} target={target} {...restProps} onClick={handleOnClick} />;
};

const withGatsbyLink = (Component: React.FunctionComponent<PropsWithChildren<AllButtonProps>>) => (
  props: PropsWithChildren<AllButtonProps & { as?: never }>
) => {
  const [gatsbyLinkProps] = useGatsbyLinkProps(props);
  return <Component {...gatsbyLinkProps} />;
};

const Button = styled(withGatsbyLink(CTAButtonBase))<PropsWithChildren<AllButtonProps & { as?: never }>>``;

Button.defaultProps = {
  variant: 'primary',
  forwardedAs: 'button',
};

Button.displayName = 'Button';

export { Button };
