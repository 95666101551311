/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { useIsMounted } from '@hooks/useIsMounted';
import { withWindow } from '@utils/withWindow';
import { segment } from '@static/js/joy';
import { Integrations } from '@static/js/types';

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: Array<{ name: string; content: string }>;
  title: string;
  name?: string;
  author?: string;
  email?: string;
  url?: string;
  fbAppId?: string;
  keywords?: string;
  allowIndexing?: boolean;
  canonicalURL?: string;
  faqData?: ReadonlyArray<{ questionText: string; answerText: string }>;
  ogImage?: string;
  ogLogo?: string;
};

export function Seo({
  description = 'The smarter way to plan your big day',
  lang = 'en',
  meta = [],
  title = 'Joy - Wedding Website, Guest List, Invitations & More',
  name = 'Joy',
  author = 'Joy',
  email = 'support@withjoy.com',
  url = 'https://withjoy.com',
  fbAppId = '123456',
  keywords,
  allowIndexing,
  faqData,
  canonicalURL,
  ogImage = 'common/meta_share/home.jpg',
  ogLogo = 'common/brand/png_purple5/logo_xl.png',
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            author
            email
            url
            fbAppId
            allowIndexing
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  title = title || site.siteMetadata.title;
  name = name || site.siteMetadata.name;
  author = author || site.siteMetadata.author;
  email = email || site.siteMetadata.email;
  url = url || site.siteMetadata.url;
  fbAppId = fbAppId || site.siteMetadata.fbAppId;
  allowIndexing = allowIndexing || site.siteMetadata.allowIndexing;

  const ldJSON = {
    '@context': 'http://schema.org/',
    '@type': 'WebSite',
    name: name + ' - ' + title,
    image: cloudAssetUrl('legacy/favicon/android-icon-192x192.png', { optimization: 'aggressive' }), // TODO: Pull image in from assets folder.
    description: metaDescription,
  };

  const isMounted = useIsMounted();

  useEffect(() => {
    withWindow(global => {
      if (isMounted) {
        segment.sendTelemetryPageView(title, canonicalURL ? canonicalURL : global.document.location.href, global.document, {
          integrations: { [Integrations.Amplitude]: true },
        });
      }
    });
  }, [canonicalURL, isMounted, title]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `og:site_name`,
          content: name,
        },
        {
          name: `og:url`,
          content: url,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: author,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: cloudAssetUrl(ogImage),
        },
        {
          property: `og:logo`,
          content: cloudAssetUrl(ogLogo),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'google-site-verification',
          content: 'cxYjaIQ1P9y_DmFhAg1l5fevNpvwjWy8-wc-yNkDsCQ',
        },
        {
          name: 'p:domain_verify',
          content: '3c9b78164292cc9faa5b7e857775406d',
        },
        {
          name: 'msapplication-TileColor',
          content: '#ffffff',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'msapplication-TileImage',
          content: '/assets/favicon/ms-icon-144x144.png', // TODO: Add image asset to this project.
        },
        {
          name: 'robots',
          content: allowIndexing ? 'index' : 'noindex',
        },
      ].concat(meta)}
    >
      {canonicalURL && <link rel="canonical" href={canonicalURL} />}
      <script type="application/ld+json">{JSON.stringify(ldJSON)}</script>
    </Helmet>
  );
}
