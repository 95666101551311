import React, { useEffect, useState, useRef } from 'react';
import { LinkVariant, JoykitColor, defaultColors } from '@components/themes';
import { StyledJoyLogo, StyledCanvasWrapper, StyledCanvas } from './ParticleJoyLogo.styles';
import { particleEffect, ParticleEffectManagerType } from './ParticleEffect';
import { useShopStorefrontContext } from '@utils/storefront/ShopStorefrontProvider';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';

interface ParticleJoyLogoProps {
  style?: React.CSSProperties;
  id?: string;
  className?: string;
  variant?: LinkVariant;
  logoColor?: JoykitColor;
  isScrollCollapsed?: boolean;
  ariaLabel?: string;
}

export const ParticleJoyLogo = ({
  id,
  style,
  className,
  variant = 'primary',
  logoColor = 'mono7',
  isScrollCollapsed = false,
  ariaLabel,
}: ParticleJoyLogoProps) => {
  const { config } = useShopStorefrontContext();
  const particleRef = useRef<HTMLCanvasElement>(null);
  const particleManager = useRef<ParticleEffectManagerType | null>(null);
  const [isParticleRunning, setIsParticleRunning] = useState(false);

  useEffect(() => {
    if (id && particleRef.current) {
      const particleEl = particleEffect(particleRef.current, defaultColors[logoColor]);
      particleEl?.changeBgColor(defaultColors[logoColor]);
      particleManager.current = particleEl;
    }
    return () => {
      particleManager?.current?.removeParticles();
    };
  }, [id, particleRef, logoColor]);

  const onHandleParticle = (e: React.MouseEvent) => {
    if (id && particleManager?.current?.initParticles) {
      if (!isParticleRunning) {
        particleManager.current.initParticles(40, e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        setIsParticleRunning(true);
        setTimeout(() => setIsParticleRunning(false), 300);
      }
    }
  };

  return (
    <StyledJoyLogo
      id={id}
      style={style}
      className={className}
      variant={variant}
      to={config.homePageUrl}
      onMouseMove={onHandleParticle}
      onClick={onHandleParticle}
      color={logoColor}
      $isScrollCollapsed={isScrollCollapsed}
      aria-label={ariaLabel}
    >
      <div style={{ position: 'relative' }}>
        <LazyLoadWrapper>
          <svg viewBox="0 0 54 34">
            <defs>
              <clipPath id={`${id}-clip-path`} clipPathUnits="objectBoundingBox">
                <path
                  className="clip-path"
                  d="M11.71 44.44a.47.47 0 0 1 .251-.221c3.007-1.428 5.568-5.77 8.22-16.968l1.158-5.055L24.4 8.9C26.834-1.85 35.204-.274 37.833.697a.47.47 0 0 1 .292.329c.018.074.018.152-.001.226a.47.47 0 0 1-.107.199c-.88.994-1.92 3.474-3.34 9.38l-4.493 18.534c-2.893 12.01-12.062 15.323-17.996 15.757a.47.47 0 0 1-.518-.349.47.47 0 0 1 .039-.332zm77.964-31.975c3.647 1.165 3.602 5.05 3.064 7.553-.944 3.926-2.453 7.694-4.482 11.186a.47.47 0 0 1-.571.211.47.47 0 0 1-.298-.531c.636-4.044-.173-8.183-2.287-11.69-2.904-4.753 1.978-7.564 4.573-6.73zm-24.982 3.53c2.767-2.72 12.085-7.918 15.458 6.067 1.335 5.265 1.268 10.787-.197 16.018s-4.274 9.986-8.15 13.794a.48.48 0 0 1-.56.056.48.48 0 0 1-.229-.513c2.698-10.135 1.018-34.393-6.037-34.622a.47.47 0 0 1-.398-.309.47.47 0 0 1 .112-.491zm2.15 38.93a.47.47 0 0 1 .532.284.47.47 0 0 1-.189.573 11.37 11.37 0 0 1-7.26 2.205c-6.86-.31-7.146-5.713-5.134-8.25a7.88 7.88 0 0 1 4.15-2.525.47.47 0 0 1 .41.163.47.47 0 0 1 .1.204c.016.075.014.153-.008.227-.903 3.005 1.418 8.18 7.398 7.12zm-28.195-37a21.01 21.01 0 0 1 13.526-5.096c7.763 0 13.33 4.96 13.33 12.57-.097 2.806-.768 5.562-1.97 8.1a20.57 20.57 0 0 1-5.026 6.652c-3.666 3.225-8.38 5.01-13.263 5.027-8.026 0-13.537-5.027-13.537-12.706a20.74 20.74 0 0 1 1.999-7.974c1.192-2.498 2.872-4.732 4.94-6.572zm8.072 24.292c4.23-2.228 7.09-13.06 7.558-14.854.606-2.08 2.424-14.59-3.636-11.643-4.076 2.03-6.853 12.227-7.5 14.604l-.03.11-.04.142c-.766 3.017-2.47 14.797 3.647 11.643zm-45.082-.994a.47.47 0 0 0 .502.177.47.47 0 0 0 .344-.406c.114-1.942 2.207-4.285 5.785-4.022 2.527.194 3.82-3.816 1.726-5.93-2.584-2.617-6.426-1.588-8.358 1.143-2.675 3.782-1.635 7.004 0 9.038z"
                  fill="currentColor"
                />
              </clipPath>
              <clipPath id="A">
                <path className="clip-path" fill="#fff" d="M0 0h93v58H0z" />
              </clipPath>
            </defs>
          </svg>
        </LazyLoadWrapper>
        {id && (
          <StyledCanvasWrapper clipPathId={`${id}-clip-path`}>
            <StyledCanvas ref={particleRef} width="54px" height="35px" isScrollCollapsed={isScrollCollapsed} />
          </StyledCanvasWrapper>
        )}
      </div>
    </StyledJoyLogo>
  );
};
