import { Flex } from '@components/Flex';
import { styled } from '@styledComponents';
import { Button } from '@components/Button';
import { TOP_BANNER_HEIGHT } from 'src/layout/Layout.constants';
import { animationTransitionExt } from '@utils/animationTransition';
import { Label2 } from '@components/typography/interVariants';
import { themeColor, themeSpace, themeMediaQuery } from '@utils/styledTheme.util';

export const StyledNavPropsButton = styled(Button)`
  ${Label2};
  height: ${TOP_BANNER_HEIGHT}px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 0px;
  padding: 0 ${themeSpace(5)};
  background: ${themeColor('black', 0.8)};
  backdrop-filter: blur(8px) saturate(180%);
  &:hover {
    background: ${themeColor('black', 0.8)};
    color: ${themeColor('white')};
  }
  &:active {
    background: ${themeColor('black', 0.8)};
    color: ${themeColor('white')};
  }

  z-index: 4;
  position: relative;
  width: 100%;
  ${themeMediaQuery('sm')} {
    top: 0;
    left: 50%;
    width: fit-content;
    position: absolute;
    transform: translate(-50%, 0);

    backdrop-filter: none;
    background: transparent;
    &:hover {
      background: transparent;
    }
    &:active {
      background: transparent;
    }
  }
`;

export const StyledNewFlag = styled(Flex)`
  column-gap: ${themeSpace(1)};
`;

export const ValuePropsContainer = styled(Flex)`
  position: absolute;
  height: ${TOP_BANNER_HEIGHT}px;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ValuePropsContent = styled(Flex)<{ show: boolean }>`
  z-index: 5;
  color: ${themeColor('white')};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  column-gap: ${themeSpace(1)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'none' : undefined)};
  transition: ${({ show }) =>
    show
      ? animationTransitionExt({ property: 'opacity', duration: '800ms', timingFunction: 'ease-in', delay: '800ms' })
      : animationTransitionExt({ property: 'opacity', duration: '800ms', timingFunction: 'ease-in', delay: '0ms' })};
`;
