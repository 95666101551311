import React from 'react';
import { AnchorButtonProps, Button } from '@components/Button';
import { useGetStartedButtonHref } from './GetStartedButtonProvider';

export const GetStartedButton: React.FC<Omit<AnchorButtonProps, 'forwardedAs' | 'href'> & {
  page: MarcomPageName | undefined;
  as?: never;
}> = ({ children, page, ...restProps }) => {
  const href = useGetStartedButtonHref(page);
  return (
    <Button forwardedAs="a" href={href} {...restProps}>
      {children}
    </Button>
  );
};
