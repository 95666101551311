import React from 'react';

export const ChevronRightIcon = () => (
  <svg
    fill="none"
    height="11"
    viewBox="0 0 11 11"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="chevron-right"
    className="chevron-right-icon"
  >
    <path d="m5.12134 2.87866 2.12132 2.12132-2.12132 2.12132" stroke="currentColor" strokeLinecap="round" strokeWidth="3" />
  </svg>
);
