import React from 'react';

export const setRef = <T>(ref: Maybe<React.Ref<T>>, value: T) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    (ref as React.MutableRefObject<T | null>).current = value;
  }
};

export const setRefs = <T>(value: T, ...refs: ReadonlyArray<Maybe<React.Ref<T>>>) => {
  refs.forEach(ref => {
    setRef(ref, value);
  });
};
