import { useContext } from 'react';
import { default as styled, ThemeContext, ThemedStyledProps, withTheme, ThemeProvider, css, keyframes } from 'styled-components';
import { Theme } from '@components/themes';

export type ThemeStyledProps<T> = ThemedStyledProps<T, Theme>;

// HOC stuff inspired from JoyKit:
/**
 * A HOC factory to get the current theme from a `JoyKitThemeProvider` and pass it to your
 * component as a `theme` prop.
 *
 * Note: Should be used within a `JoyKitThemeProvider` hierarchy.
 *
 * @example
 *
 * ```
 * class MyComponent extends React.Component {
 *    render() {
 *      console.log('Current theme: ', this.props.theme)
 *      // ...
 *    }
 *  }
 * ```
 *
 * export const MyComponentWithTheme = withTheme(MyComponent);
 */

const useTheme = (): Theme => {
  return useContext(ThemeContext);
};

export { styled, css, ThemeProvider, withTheme, useTheme, keyframes };
