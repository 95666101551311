import React from 'react';
import { headerData } from '@data-ts/shared/header';
import { getRootUrl } from '@utils/urlHelpers.util';
import { UniversalFooterLink } from './Footer.styles';

const footerNavData = headerData.footer;

interface FooterMenu {
  id: string;
}

export const FooterMenu: React.FC<FooterMenu> = ({ id }) => {
  return (
    <>
      <UniversalFooterLink
        id={`universalFooterShipping${id}`}
        href={getRootUrl('/shipping-policy')}
        variant="footer"
        typography="featureCopy17"
      >
        {footerNavData.shipping}
      </UniversalFooterLink>
      <UniversalFooterLink
        id={`universalFooterReturns${id}`}
        href={getRootUrl('/return-policy')}
        variant="footer"
        typography="featureCopy17"
      >
        {footerNavData.returns}
      </UniversalFooterLink>
      <UniversalFooterLink id={`universalFooterNewsroom${id}`} href={getRootUrl('/newsroom/')} variant="footer" typography="featureCopy17">
        {footerNavData.newsroom}
      </UniversalFooterLink>
      <UniversalFooterLink id={`universalFooterCareers${id}`} href={getRootUrl('/careers/')} variant="footer" typography="featureCopy17">
        {footerNavData.careers}
      </UniversalFooterLink>
      <UniversalFooterLink id={`universalFooterAboutJoy${id}`} href={getRootUrl('/our-story/')} variant="footer" typography="featureCopy17">
        {footerNavData.aboutJoy}
      </UniversalFooterLink>
      <UniversalFooterLink id={`universalFooterContactUs${id}`} href="mailto:hello@withjoy.com" variant="footer" typography="featureCopy17">
        {footerNavData.contactUs}
      </UniversalFooterLink>
    </>
  );
};
