import React from 'react';
import { styled } from '@styledComponents';
import { MarcomSpaceProps, system } from 'styled-system';
import { Flex } from '@components/Flex';
import { JoykitColor } from '@components/themes';
import { getIconDefinition, JoykitIconVariant } from './JoykitIconsSet';
import { getIconSvgDefinition, JoykitIconSvgVariant } from './JoykitIconsSvgSet';
import { JoykitIconSize } from '@components/themes/createTheme.util';
import { Image } from '@components/Image';

interface IconProps extends MarcomSpaceProps {
  currentColor?: JoykitColor;
  isInline?: boolean;
  size: JoykitIconSize | JoykitIconSize[];
}

const IconWrapper = styled(Flex)<IconProps>`
  display: ${({ isInline }) => (isInline ? 'inline-flex' : 'flex')};
  ${system({
    size: { properties: ['width', 'height'], scale: 'joykitIconSize' },
  })}
  > img,
  > svg {
    width: 100%;
    height: 100%;
    ${system({
      currentColor: {
        property: 'color',
        scale: 'colors',
      },
    })}
  }
`;

export interface JoykitIconProps extends MarcomSpaceProps {
  name: JoykitIconVariant;
  color: JoykitColor;
  size?: JoykitIconSize | JoykitIconSize[];
  isInline?: boolean;
}

export const JoykitIcon: React.FC<JoykitIconProps> = ({ color, name, size, isInline, ...restProps }) => {
  const iconDefinition = getIconDefinition(name);
  const IconSvg = iconDefinition.svg;
  return (
    <IconWrapper currentColor={color} isInline={isInline} size={size || iconDefinition.defaultSize} {...restProps}>
      <IconSvg aria-label={iconDefinition.a11yLabel} role={'img'} />
    </IconWrapper>
  );
};

export interface JoykitIconSvgProps extends MarcomSpaceProps {
  name: JoykitIconSvgVariant;
  color: JoykitColor;
  size?: JoykitIconSize | JoykitIconSize[];
  isInline?: boolean;
}

export const JoykitIconSvg: React.FC<JoykitIconSvgProps> = ({ color, name, size, isInline, ...restProps }) => {
  const iconSvgDefinition = getIconSvgDefinition(name);
  const IconSvg = iconSvgDefinition.svg;
  return (
    <IconWrapper currentColor={color} isInline={isInline} size={size || iconSvgDefinition.defaultSize} {...restProps}>
      <Image src={IconSvg} alt="React Logo" />
    </IconWrapper>
  );
};
