import { readonlyStrEnum } from '@utils/readonlyStrEnum';
import { JoykitIconSize } from '@components/themes/createTheme.util';

const BreakpointSize = {
  below_sm: 'below_sm' as 'below_sm',
  tablet: 'tablet' as 'tablet',
  sm: 'sm' as 'sm',
  md: 'md' as 'md',
  lg: 'lg' as 'lg',
  xl: 'xl' as 'xl',
};

export type BreakpointSize = typeof BreakpointSize[keyof typeof BreakpointSize];

export type Breakpoints = [string, string, string, string];

export type MediaQueries = { [breakpoint in BreakpointSize]: string };
export type MaxColumnsWidths = { [breakpoint in BreakpointSize]: number };

export type SpaceScale = string[];
export type JoykitIconSizeScale = Readonly<JoykitIconSize[]>;

export type PrimitiveColorTokens = {
  purple1: string;
  purple2: string;
  purple3: string;
  purple4: string;
  purple5: string;
  purple6: string;
  newPurple5: string; // Updated color for find a couple UI.
  blue1: string;
  blue2: string;
  blue3: string;
  blue4: string;
  blue5: string;
  blue6: string;
  green1: string;
  green2: string;
  green3: string;
  green4: string;
  green5: string;
  green6: string;
  yellow1: string;
  yellow1A70: string;
  yellow2: string;
  yellow3: string;
  yellow4: string;
  yellow5: string;
  yellow6: string;
  yellowShade3: string;
  red1: string;
  red2: string;
  red3: string;
  red4: string;
  red5: string;
  red6: string;
  actionPurple9: string;
  actionPurple8: string;
  actionPurple7: string;
  actionPurple4: string;
  actionPurple3: string;
  actionPurple2: string;
  actionPurple1: string;
  mono7: string;
  mono6: string;
  mono5: string;
  mono4: string;
  mono3: string;
  mono2Fitty: string;
  mono2: string;
  mono1: string;
  mono12: string;
  humana1: string;
  humana2: string;
  humana3: string;
  humana4: string;
  humana5: string;
  humana6: string;
  neutra1: string;
  neutra2: string;
  neutra3: string;
  neutra4: string;
  neutra5: string;
  neutra6: string;
  link: string;
  white: string;
  black: string;
  footerText: string;
  navbar: string;
  primeTextBlue: string;
  primeHoverBlue: string;
  primeActiveBlue: string;
  announcementPurple: string;
  warmGray1: string;
  humanaDark4: string;
  humanaDark6: string;
  accentBlue5: string;
  accentBlue4: string;
  humana1Light: string;
  accentBlue6: string;
  accentBlue1: string;
  humana2Light: string;
  blackDarkTheme: string;
};

export type ComponentColorTokens = {
  linkDefault: string;
  linkHover: string;
  linkPress: string;
  linkFooterDefault: string;
  linkFooterHover: string;
  linkFooterPress: string;
  linkHomeFeature: string;
  linkHomeFeatureHover: string;
  ctaDefault: string;
  ctaHover: string;
  ctaPress: string;
  secondaryCtaDefault: string;
  secondaryCtaHover: string;
  secondaryCtaPress: string;
  covidCalculatorLowRiskIndicator: string;
  covidCalculatorMediumRiskIndicator: string;
  covidCalculatorHighRiskIndicator: string;
};

export type Colors = Readonly<PrimitiveColorTokens & ComponentColorTokens>;
export type JoykitColor = keyof Colors;

export type LinkVariantStyle = {
  color: JoykitColor;
  '&:hover': {
    color: JoykitColor;
    fontStyle?: string;
  };
  '&:active': {
    color: JoykitColor;
  };
};

export const linkVariants = [
  'primary',
  'alternative',
  'footer',
  'footerAlt',
  'footerAltDefault',
  'altPurple',
  'altGreen',
  'altBlue',
  'altRed',
  'homeFeature',
  'humanaLink',
  'redLink',
  'yellowLink',
  'navLink',
  'neutraLink',
  'humanaDarkLink',
] as const;

export const LinkVariant = readonlyStrEnum(linkVariants);

export type LinkVariant = keyof typeof LinkVariant;
export type LinkVariantsSet = {
  [variant in LinkVariant]: LinkVariantStyle;
};

export type TypographyTextStyle = {
  fontFamily: string;
  fontStyle?: string;
  fontWeight?: number | 'inherit' | '-moz-initial' | 'initial' | 'revert' | 'unset' | 'normal' | 'bold' | 'bolder' | 'lighter' | undefined;
  fontSize: string;
  lineHeight: string;
  letterSpacing?: string;
};

export const typographyVariants = [
  'hed120SuperEmphasis',
  'hed120Super',
  'hed96Break',
  'hed72Hero',
  'hed56',
  'hed40',
  'hed32',
  'calloutHed40Bold',
  'calloutHed40',
  'calloutHed48',
  'calloutHed32',
  'calloutDek40',
  'calloutDek32',
  'calloutHed24',
  'sectionEyeBrow24',
  'sectionEyeBrow20',
  'dek24',
  'austinNewsDek24',
  'dek20',
  'dek15',
  'featureHed17',
  'featureHed20',
  'featureCopy17',
  'caption',
  'legal',
  'stat',
  'hed96SuperEmphasis',
  'hed56SuperEmphasis',
  'hed32Mobile',
  'dek32',
  'dek40',
  'navParent15',
  'navParent18',
  'navChild32',
  'navChild36',
  'navSearch48',
  'navSearch122',
  'navSearch148',
] as const;

export const TypographyVariant = readonlyStrEnum(typographyVariants);

export type TypographyVariant = typeof TypographyVariant[keyof typeof TypographyVariant];
export type TypographyVariantSet = {
  [variant in TypographyVariant]: TypographyTextStyle;
};

export const typographyMappingDesktopXlVariants = [
  'hed120SuperEmphasis',
  'hed120Super',
  'hed96Break',
  'hed72Hero',
  'hed56',
  'hed40',
  'calloutHed40Bold',
  'calloutHed48',
  'calloutHed48',
  'calloutHed40',
  'calloutDek40',
  'calloutHed32',
  'sectionEyeBrow24',
  'austinNewsDek24',
  'dek24',
  'dek20',
  'dek15',
  'featureHed20',
  'featureHed17',
  'featureCopy17',
  'caption',
  'legal',
  'stat',
  'navParent15',
  'navParent18',
  'navChild36',
  'navSearch148',
  'sectionEyeBrow20',
] as const;

export const typographyMappingTabletDesktopVariants = [
  'hed96SuperEmphasis',
  'hed56SuperEmphasis',
  'hed96Break',
  'hed72Hero',
  'hed56',
  'hed40',
  'hed32',
  'calloutHed40',
  'calloutHed32',
  'calloutDek32',
  'sectionEyeBrow24',
  'sectionEyeBrow20',
  'dek32',
  'featureHed20',
  'featureHed17',
  'featureCopy17',
  'austinNewsDek24',
  'dek20',
  'dek15',
  'caption',
  'legal',
  'navParent15',
  'navParent18',
  'navChild36',
  'navSearch122',
] as const;

export const typographyMappingMobileVariants = [
  'hed96SuperEmphasis',
  'hed56SuperEmphasis',
  'hed72Hero',
  'hed56',
  'hed40',
  'hed32',
  'calloutHed32',
  'calloutHed24',
  'calloutDek32',
  'dek32',
  'featureHed20',
  'featureHed17',
  'featureCopy17',
  'dek20',
  'austinNewsDek24',
  'dek15',
  'caption',
  'legal',
  'navParent15',
  'navParent18',
  'navChild32',
  'navSearch48',
  'sectionEyeBrow20',
  'sectionEyeBrow24',
] as const;

const TypographyMappingDesktopXlVariant = readonlyStrEnum(typographyMappingDesktopXlVariants);

const TypographyMappingTabletDesktopVariant = readonlyStrEnum(typographyMappingTabletDesktopVariants);

const TypographyMappingMobileVariant = readonlyStrEnum(typographyMappingMobileVariants);

export type TypographyMappingDesktopXlVariant = typeof TypographyMappingDesktopXlVariant[keyof typeof TypographyMappingDesktopXlVariant];
export type TypographyMappingTabletDesktopVariant = typeof TypographyMappingTabletDesktopVariant[keyof typeof TypographyMappingTabletDesktopVariant];
export type TypographyMappingMobileVariant = typeof TypographyMappingMobileVariant[keyof typeof TypographyMappingMobileVariant];

export type GlobalTokens = Readonly<{
  rootFontSize: number;
}>;

export interface Theme
  extends Readonly<{
    global: GlobalTokens;
    breakpoints: Breakpoints;
    maxColumnsWidths: MaxColumnsWidths;
    mediaQueries: MediaQueries;
    space: SpaceScale;
    colors: Colors;
    typography: TypographyVariantSet;
    direction: 'ltr';
    links: LinkVariantsSet;
    joykitIconSize: JoykitIconSizeScale;
  }> {}

export type TagName = 'p' | 'div' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
