import React, { useLayoutEffect, useState, useEffect, Suspense } from 'react';
import './normalize.css';
import './layout.css';
import 'src/components/typography/typography.css';
import { ScrollControllerProvider } from '@context/ScrollControllerProvider/ScrollControllerProvider';
import { Footer, Header } from '@layout';
import { ButtonVariant } from '@components/Button';
import { JoykitColor, LinkVariant } from '@components/themes';
import { ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component';
import { FooterNavs } from './FooterNavs';
import { IframeMessageBusProvider } from '@utils/iframeMessageBus/IframeMessageBusProvider';
import { useScrollDepthTracking } from '@utils/useScrollDepthTracking';
import { ShopStorefrontProvider } from '@utils/storefront/ShopStorefrontProvider';

const ExperimentsModal = React.lazy(() => import('@utils/experiments/ExperimentsModal'));

interface LayoutProps {
  isTransparentHeader?: boolean;
  headerScrollThresholdVh?: number;
  headerRef?: React.RefObject<HTMLElement>;
  transparentJoyLogoColor?: JoykitColor;
  transparentHeaderLinkVariant?: LinkVariant;
  transparentHeaderButtonVariant?: ButtonVariant;
  scrollPosition: ScrollPosition;
  chromeless?: boolean;
  page?: MarcomPageName;
  hideValueProps?: boolean;
  handleGetStarted?: (e: React.MouseEvent) => void;
}

const InternalLayout: React.FC<LayoutProps> = ({
  children,
  headerScrollThresholdVh,
  isTransparentHeader = false,
  hideValueProps = false,
  headerRef,
  transparentJoyLogoColor,
  transparentHeaderLinkVariant,
  transparentHeaderButtonVariant,
  scrollPosition,
  chromeless,
  page,
  handleGetStarted,
}) => {
  const [headerScrollThreshold, setHeaderScrollThreshold] = useState<number | undefined>();
  useScrollDepthTracking();
  useLayoutEffect(() => {
    if (headerScrollThresholdVh !== undefined) {
      setHeaderScrollThreshold((window.innerHeight * headerScrollThresholdVh) / 100 + 50);
    }
  }, [headerScrollThresholdVh]);

  useEffect(() => {
    const element = document.getElementById('___gatsby');
    if (element) {
      element.style.overflowX = 'hidden';
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleHamburgerLongPress = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    const handleEvents = function(e: KeyboardEvent) {
      if (e.keyCode === 69 && e.altKey && (e.ctrlKey || e.metaKey)) {
        setIsModalOpen(!isModalOpen);
      }
    };

    document.addEventListener('keydown', handleEvents);
    return () => {
      document.removeEventListener('keydown', handleEvents);
    };
  }, [isModalOpen]);

  return (
    <IframeMessageBusProvider>
      <ShopStorefrontProvider page={page}>
        <ScrollControllerProvider scrollPosition={scrollPosition}>
          <>
            {!chromeless ? (
              <Header
                ref={headerRef}
                scrollThreshold={headerScrollThreshold}
                isTransparent={isTransparentHeader}
                transparentJoyLogoColor={transparentJoyLogoColor}
                transparentHeaderLinkVariant={transparentHeaderLinkVariant}
                transparentHeaderButtonVariant={transparentHeaderButtonVariant}
                page={page}
                handleGetStarted={handleGetStarted}
                handleHamburgerLongPress={handleHamburgerLongPress}
                hideValueProps={hideValueProps}
              />
            ) : null}
            <main>
              <FooterNavs />
              {children}
            </main>
            {!chromeless ? <Footer page={page} handleGetStarted={handleGetStarted} /> : null}
            {isModalOpen && (
              <Suspense fallback={<></>}>
                <ExperimentsModal isModalOpen={isModalOpen} handleClose={handleClose} />
              </Suspense>
            )}
          </>
        </ScrollControllerProvider>
      </ShopStorefrontProvider>
    </IframeMessageBusProvider>
  );
};

export const Layout = trackWindowScroll(InternalLayout);
