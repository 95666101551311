import { themeMediaQuery, themeColor } from '@utils/styledTheme.util';
import { animationTransition } from '@utils/animationTransition';
import { styled } from '@styledComponents';
import { Link } from 'gatsby';
import { LinkVariant, JoykitColor } from '@components/themes';
import { variant } from 'styled-system';

export const JOY_LOGO_WIDTH_MOBILE = 44;
export const JOY_LOGO_HEIGHT_MOBILE = 26;
export const JOY_LOGO_WIDTH_DESKTOP = 64;

export const StyledJoyLogo = styled(Link)<{ variant?: LinkVariant; color?: JoykitColor; $isScrollCollapsed?: boolean }>`
  position: relative;
  display: flex;
  transition: ${animationTransition('color', 'transform')};
  margin-right: 0px;
  color: ${({ color }) => (color ? themeColor(color) : '')};

  ${variant({
    scale: 'links',
    variants: {
      primary: {},
    },
  })}
  & svg {
    width: ${JOY_LOGO_WIDTH_MOBILE}px;
    height: ${JOY_LOGO_HEIGHT_MOBILE}px;

    path.clip-path {
      width: unset;
      transform: scale(0.01075, 0.0155);
    }
    ${themeMediaQuery('sm')} {
      width: ${JOY_LOGO_WIDTH_DESKTOP}px;
      height: inherit;
      margin-top: 0px;
    }
  }

  &:not(.footer-joy-icon) {
    margin-top: ${({ $isScrollCollapsed }) => ($isScrollCollapsed ? 3 : 0)}px;
    ${themeMediaQuery('below_sm')} {
      margin-top: 0px;
    }
  }

  :hover {
    transform: scale(1.1);
    color: ${({ color }) => (color ? themeColor(color) : '')};
  }
`;

export const StyledCanvasWrapper = styled.div<{ clipPathId: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  clip-path: url('#${props => props.clipPathId}');
`;

export const StyledCanvas = styled.canvas<{ isScrollCollapsed: boolean }>`
  width: ${JOY_LOGO_WIDTH_MOBILE}px;
  height: intrinsic;
  ${themeMediaQuery('below_sm')} {
    transform: translateY(-1px);
  }
  ${themeMediaQuery('sm')} {
    width: ${JOY_LOGO_WIDTH_DESKTOP}px;
  }
`;
