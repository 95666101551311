import { themeColor, themeMediaQuery, themeSpace, themeVariant } from '@utils/styledTheme.util';
import { styled } from '@styledComponents';
import { createGlobalStyle } from 'styled-components';
import { Button } from '@components/Button';

export const ManageButton = styled(Button)<{ writersblock?: boolean; noreferrer?: boolean; noopener?: boolean }>`
  padding: 0px;
  height: auto;
  border-width: 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  color: ${({ writersblock }) => (writersblock ? '#9069dd' : themeColor('white'))};
  &:hover {
    border-width: 0px;
    background-color: transparent;
    color: ${({ writersblock }) => (writersblock ? themeColor('linkHover') : themeColor('mono3'))};
  }
  &:active {
    color: ${({ writersblock }) => (writersblock ? themeColor('purple4') : themeColor('white'))};
  }
  ${themeVariant('dek15')};
`;

export const BannerGlobalStyles = createGlobalStyle`
  #polaris-consent-widgets {
    h3 {
      color: ${themeColor('mono1')};
      ${themeVariant('featureHed17')};
      margin: 0px;
      height: 32px;
    }

    h2 {
      color: ${themeColor('mono1')};
      ${themeVariant('sectionEyeBrow24')};
      margin-bottom: ${themeSpace(3)};
    }

    .polaris-banner{    
      box-shadow: none;
      backdrop-filter: blur(${themeSpace(3)});
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: ${themeSpace(3)};
      width: 328px;
      left: initial;
      right: ${themeSpace(6)};
      bottom: ${themeSpace(6)};
      padding: ${themeSpace(5)} ${themeSpace(6)} ${themeSpace(5)};
      ${themeMediaQuery('below_sm')} {
        width: fit-content;
        left: ${themeSpace(6)};
      }

      p {
        font-size: 13px;
        font-family: AkkuratLLSub-RegularSubset, sans-serif;
        color: ${themeColor('neutra5')};
        line-height: 20.8px;
      }
    }

    button.polaris-preferences-fab {
      display: none;
    }

    .polaris-banner > div{
      box-shadow: none;
    }

    footer{
      min-height: fit-content;
      margin-top: ${themeSpace(6)};
      background-color: transparent;
  
      button {
        font-weight: 700;
        font-size: 13px;
        line-height: 26px;
        display: flex;
        align-items: center;
        font-family: AkkuratLLSub-RegularSubset, sans-serif;
        letter-spacing: 0.01em;
        padding: 7px ${themeSpace(6)};
        border-radius: 4px;
        text-transform: capitalize;
        margin-bottom: ${themeSpace(3)};
        width: 100%;
        margin-left: 0px;
      }

      button:nth-child(1) {
        border-width: 0px;
        color: ${themeColor('white')};
        background-color: ${themeColor('neutra6')};
        &:hover {
          background-color: ${themeColor('neutra4')};
        }
        &:active {
          background-color: ${themeColor('neutra5')};
        }
      }

      button:nth-child(2) {
        border: 1px solid ${themeColor('neutra6')};
        color: ${themeColor('neutra6')};
        background-color: transparent;
        &:hover {
          color: ${themeColor('neutra4')};
          border: 1px solid ${themeColor('neutra4')};
        }
        &:active {
          color: ${themeColor('neutra5')};
          border: 1px solid ${themeColor('neutra5')};
        }
      }
  
      a {
        font-family: AkkuratLLSub-RegularSubset, sans-serif;
        font-weight: 700;
        font-size: 13px;
        line-height: 26px;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        color: ${themeColor('neutra6')};
        width:100%;
        margin-bottom: 0px;
        &:hover {
          color: ${themeColor('neutra4')};
        }
        &:active {
          color: ${themeColor('neutra5')};
        }
      }

      span, small {
        display: none;
      }
    }

    footer > div,
    footer > div > div {
      margin-bottom: 0px;
      flex-direction: column;
    }

    footer > div > div > div {
      flex-wrap: wrap;
    }

    .polaris-modal > header {
      position: relative;
      padding: ${themeSpace(7)} ${themeSpace(9)} ${themeSpace(7)};
      ${themeMediaQuery('below_sm')} {
        padding: ${themeSpace(7)};
      }
      
      h2 {
        margin-bottom: 0px;
      }

      button {
        top: ${themeSpace(6)};
        right: ${themeSpace(6)};
        position: absolute;
        opacity: 1;
        height: auto;
        
        &:hover {
          opacity: 0.3;
        }

        &:active {
          opacity: 0.6;
        }
      }

      .polaris-modal__close_icon:before,
      .polaris-modal__close_icon:after {
        left: auto;
      }
    }

    .polaris-modal__overlay {
      top: ${themeSpace(6)};
    }

    .polaris-modal {
      max-width: 440px;
      margin: ${themeSpace(6)};
      border-width: 0px;
      box-shadow: none;
      border-radius: ${themeSpace(3)};
      background-color: white;

      ${themeMediaQuery('below_sm')} {
        margin: 0px;
        border-radius: ${themeSpace(3)} ${themeSpace(3)} 0px 0px;
      }
  
      button {
        height: 48px;
        font-size: 15px;
        line-height: 24px;
      }
      
      p {
        font-size: 15px;
        line-height: 24px;
        color: #595959;
      }

      .polaris-modal__body {
        padding: 0px ${themeSpace(9)};
        ${themeMediaQuery('below_sm')} {
          padding: 0px ${themeSpace(7)};
        }

        header {
          margin-bottom: ${themeSpace(3)};
        }

        span::before,
        input:checked+span::before {
          padding: 4px;
          width: 56px;
          height: 32px;
          border-radius: 60px;
        }

        input {
          width: 0px;
        }

        span::before {
          background-color: var(--gray-light);
        }

        input:checked+span::before {
          background: #502080;
        }

        input:disabled+span:before {
          opacity: 1;
          background-color: #D0CCFF;
        }

        span::after,
        input:checked+span::after {
          top: 9px;
          right: 20px;
          width: 24px;
          height: 24px;
        }

        span::after {
          right: 27px;
          background-color: white;
        }
      }

      .polaris-modal__body > div {
        margin-bottom: ${themeSpace(7)};
      }

      footer {
        margin-top: 0px;
        padding: ${themeSpace(5)} ${themeSpace(9)} ${themeSpace(7)};
        ${themeMediaQuery('below_sm')} {
          padding: ${themeSpace(5)} ${themeSpace(7)} ${themeSpace(7)};
        }
      }
      
      footer.polaris-modal__footer > div > div {
        flex-direction: column-reverse;
      }

      footer.polaris-modal__footer > div > div > button:nth-child(1) {
        margin-bottom: 0px;
      }

      footer.polaris-modal__footer > div > div > button:nth-child(2) {
        margin-bottom: ${themeSpace(5)};
      }

      footer.polaris-modal__footer > div > div > button:nth-child(1),
      footer.polaris-modal__footer > div > div > button:nth-child(2) {
        border-width: 0px;
        color: ${themeColor('white')};
        background-color: ${themeColor('neutra6')};
        &:hover {
          background-color: ${themeColor('neutra4')};
        }
        &:active {
          background-color: ${themeColor('neutra5')};
        }
      }
    }

  }
`;
