import React from 'react';
import { Flex } from '@components/Flex';
import { FooterMenu } from './FooterMenu';
import { FooterSocial } from './FooterSocial';
import { headerData } from '@data-ts/shared/header';
import { getRootUrl } from '@utils/urlHelpers.util';
import { FooterPrivacyMenu } from './FooterPrivacyMenu';
import { ABExperimentName } from '@utils/experiments/experiments';
import { footerPageId } from 'src/layout/components/Header/Header.utils';
import { useExperimentsTelemetry } from '@utils/experiments/useExperimentsTelemetry';
import { useShopStorefrontContext } from '@utils/storefront/ShopStorefrontProvider';
import { CookiesConsentBanner, CookiesConsentBannerStyles } from 'src/layout/CookiesConsentBanner';
import {
  FooterMenuContainer,
  FooterColumnContainer,
  FooterColumns,
  FooterColumn,
  FooterColumnBottom,
  FooterMenuCTALink,
  FooterSubMenuCTALink,
  FooterMenuWrapper,
  FooterSubMenuWrapper,
  UniversalFooter,
  UniversalFooterLink,
  NewFlagText,
  UniversalFooterMenuWrapper,
  StyledGetStartedButton,
  StyledButton,
  CopyrightText,
  FooterCopyrightContainer,
  UniversalFooterContainer,
  FooterMenuDisplayAboveMobile,
  UniversalCtaContainer,
  StyledParticleJoyLogo,
  FooterMenuDisplayOnMobile,
  UniversalFooterSocialMenuWrapper,
  UniversalFooterWrapper,
  UniversalFooterLogoContainer,
  FooterSubMenuContainer,
  StyledFooter,
  TradeMarkContainer,
  TradeMarkText,
  ContentWrapper,
  StyledLetterSlider,
  WithJoyText,
} from './Footer.styles';

const universalNavData = headerData.universalNav;
const footerNavData = headerData.footer;

export const Footer: React.FC<{ page?: MarcomPageName; handleGetStarted?: (e: React.MouseEvent) => void }> = ({
  page,
  handleGetStarted,
}) => {
  const { config } = useShopStorefrontContext();

  useExperimentsTelemetry({ name: ABExperimentName.HotelRoomBlocksExperiment });

  return (
    <StyledFooter id={footerPageId({ page })}>
      <FooterMenuContainer>
        <FooterColumnContainer>
          <FooterColumns>
            <FooterColumn desktop="half" mobile="full">
              <FooterMenuWrapper flexDirection="column">
                {config.footerMainMenuOptions.map(menu => (
                  <FooterMenuCTALink
                    key={menu.id}
                    id={menu.id}
                    href={menu.to}
                    target={menu.target}
                    variant="footerAltDefault"
                    typography={['hed32Mobile', 'hed72Hero']}
                  >
                    {menu.title}
                  </FooterMenuCTALink>
                ))}
              </FooterMenuWrapper>
            </FooterColumn>
            <FooterColumn desktop="half" mobile="full">
              <FooterSubMenuContainer>
                <FooterSubMenuWrapper justifyContent="flex-start" flexDirection="column">
                  {config.footerMenuOptions.map(menu => (
                    <FooterSubMenuCTALink
                      key={menu.id}
                      id={menu.id}
                      href={menu.to}
                      target={menu.target}
                      variant="footerAltDefault"
                      typography="sectionEyeBrow24"
                    >
                      {menu.title}
                    </FooterSubMenuCTALink>
                  ))}
                </FooterSubMenuWrapper>
              </FooterSubMenuContainer>
            </FooterColumn>
          </FooterColumns>
        </FooterColumnContainer>
      </FooterMenuContainer>
      <TradeMarkContainer>
        <ContentWrapper>
          <TradeMarkText>
            <StyledLetterSlider content={footerNavData.tradeMarkContent} />
          </TradeMarkText>
          <WithJoyText>{footerNavData.withJoyTM}</WithJoyText>
        </ContentWrapper>
      </TradeMarkContainer>
      <UniversalFooterWrapper backgroundColor="mono1">
        <FooterColumnContainer>
          <FooterColumns>
            <FooterColumnBottom width="full">
              <UniversalFooter flexDirection="column" paddingY={[8, 9]}>
                <UniversalFooterContainer justifyContent="space-between">
                  <UniversalFooterMenuWrapper>
                    <UniversalFooterLink id="universalFooterMenuWedding" href={getRootUrl('/')} variant="footer" typography="featureCopy17">
                      {universalNavData.joyWedding}
                    </UniversalFooterLink>
                    <UniversalFooterLink
                      id="universalFooterMenuBabyRegistry"
                      href={getRootUrl('/baby-registry/')}
                      variant="footer"
                      typography="featureCopy17"
                    >
                      {universalNavData.joyBaby}
                      <NewFlagText variant="dek20" color="mono1" tagName="span" marginLeft={3}>
                        {universalNavData.new}
                      </NewFlagText>
                    </UniversalFooterLink>
                  </UniversalFooterMenuWrapper>
                  <FooterMenuDisplayAboveMobile>
                    <FooterMenu id="Desktop" />
                  </FooterMenuDisplayAboveMobile>
                </UniversalFooterContainer>
                <UniversalFooterContainer justifyContent="space-between">
                  <UniversalCtaContainer>
                    <StyledGetStartedButton
                      id="universalFooterGetStarted"
                      page={page}
                      variant="defaultPrimeWeddingWebsites"
                      onClick={handleGetStarted}
                    >
                      {footerNavData.getStarted}
                    </StyledGetStartedButton>
                    <StyledButton
                      id="universalFooterLogin"
                      variant="defaultPrimary"
                      forwardedAs="a"
                      href={getRootUrl('/login')}
                      isLogin={true}
                    >
                      {footerNavData.login}
                    </StyledButton>
                    <StyledButton
                      id="universalFooterFindAnEvent"
                      variant="defaultPrimary"
                      forwardedAs="a"
                      href={getRootUrl('/find/')}
                      isLogin={false}
                    >
                      {footerNavData.findAnEvent}
                    </StyledButton>
                  </UniversalCtaContainer>
                  <FooterMenuDisplayOnMobile>
                    <Flex flexDirection="column" style={{ rowGap: '48px' }}>
                      <FooterMenuDisplayOnMobile>
                        <FooterMenu id="Mobile" />
                      </FooterMenuDisplayOnMobile>
                      <FooterMenuDisplayOnMobile>
                        <FooterPrivacyMenu id="Mobile" />
                      </FooterMenuDisplayOnMobile>
                    </Flex>
                  </FooterMenuDisplayOnMobile>
                  <UniversalFooterLogoContainer alignItems={['flex-start', 'center']}>
                    <UniversalFooterSocialMenuWrapper justifyContent="center">
                      <FooterSocial />
                    </UniversalFooterSocialMenuWrapper>
                    <StyledParticleJoyLogo id="universalFooterLogo" variant="primary" ariaLabel="Footer Joy Logo" />
                  </UniversalFooterLogoContainer>
                </UniversalFooterContainer>
                <FooterCopyrightContainer justifyContent="space-between" paddingTop={3}>
                  <CopyrightText variant="navParent18" tagName="span">
                    © {new Date().getFullYear()} Joy. {footerNavData.copyright}
                    <CookiesConsentBanner id="Desktop" />
                  </CopyrightText>
                  <FooterMenuDisplayAboveMobile>
                    <FooterPrivacyMenu id="Desktop" />
                  </FooterMenuDisplayAboveMobile>
                </FooterCopyrightContainer>
              </UniversalFooter>
            </FooterColumnBottom>
          </FooterColumns>
        </FooterColumnContainer>
      </UniversalFooterWrapper>
      <CookiesConsentBannerStyles />
    </StyledFooter>
  );
};
