import React from 'react';
import { flexbox, MarcomFlexboxProps } from 'styled-system';
import styled from 'styled-components';
import { Box, BoxProps } from '@components/Box';

export interface FlexProps extends Omit<MarcomFlexboxProps, 'display'> {}

const Flex = styled(Box)<FlexProps & BoxProps>`
  display: flex;
  ${flexbox}
`;

Flex.displayName = 'Flex';

export { Flex };
