import styled, { css, DefaultTheme } from 'styled-components';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { ColumnsProps } from './Columns';
import { compose, flex, MarcomMarginProps, MarcomPaddingProps, margin, padding, width } from 'styled-system';
import { ColumnGap } from './Columns.types';
import { ThemeStyledProps } from '@components/styledComponents';
import { BreakpointSize, MediaQueries } from '@components/themes';
import { FlexProps } from '@components/Flex';
import { getColumnGap } from '@components/Columns';

// Responsive style functions
export const calculateContainerWidth = (theme: DefaultTheme, mediaSize: Exclude<BreakpointSize, 'below_sm' | 'tablet'> | 'xs') => {
  const columnGap = columnGapsMap[mediaSize];
  const gutterInPixels = Number.parseFloat(getColumnGap(theme, columnGap)) * 16;
  let maxWidth = 540;
  if (mediaSize !== 'xs') {
    maxWidth = theme.maxColumnsWidths[mediaSize];
  }

  // Inner gutters are not collapsed, so we'll
  return `${(maxWidth + gutterInPixels * 4) / 16}rem`;
};

export const columnGapsMap: { [breakpoint in Exclude<BreakpointSize, 'below_sm' | 'tablet'> | 'xs']: ColumnGap } = {
  xs: 'sm',
  sm: 'sm',
  md: 'md',
  lg: 'md',
  xl: 'md',
};

const composedResponsiveFunctions = compose(margin, padding, flex, width);

const shouldForwardProp = createShouldForwardProp([
  ...(composedResponsiveFunctions.propNames || []),
  'width',
  'centered',
  'fluid',
  'columnGap',
  'multiline',
  'stackOnMobile',
  'notlastChildMargin',
  'lastChildMargin',
]);

// Breakpoint Rules

const xsBreakpointRule = css`
  @media screen and (min-width: ${props => 576 / props.theme.global.rootFontSize}rem) {
    max-width: ${props => calculateContainerWidth(props.theme, 'xs')};
  }
`;

const smBreakpointRule = css`
  ${props => props.theme.mediaQueries.sm} {
    max-width: ${props => calculateContainerWidth(props.theme, 'sm')};
  }
`;
const mdBreakpointRule = css`
  ${props => props.theme.mediaQueries.md} {
    max-width: ${props => calculateContainerWidth(props.theme, 'md')};
  }
`;
const lgBreakpointRule = css`
  ${props => props.theme.mediaQueries.lg} {
    max-width: ${props => calculateContainerWidth(props.theme, 'lg')};
  }
`;
const xlBreakpointRule = css`
  ${props => props.theme.mediaQueries.xl} {
    max-width: ${props => calculateContainerWidth(props.theme, 'xl')};
  }
`;

const mediaRules = [xsBreakpointRule, smBreakpointRule, mdBreakpointRule, lgBreakpointRule, xlBreakpointRule];

const fluidMediaRuleByBreakpoint: Readonly<{ [key in keyof Omit<MediaQueries, 'below_sm' | 'tablet'>]: number }> = {
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
};

type ColumnsOuterProps = Pick<ColumnsProps, 'fluid'> & MarcomMarginProps & Pick<MarcomPaddingProps, 'paddingX'>;

export const fluidMediaQueryRules = ({ fluid, theme }: ThemeStyledProps<ColumnsOuterProps>) => {
  if (!fluid) {
    // If falsy, apply all media queries
    return mediaRules;
  }
  if (fluid === true) {
    // 100% width at all viewports
    return null;
  }
  return mediaRules.slice(
    (fluidMediaRuleByBreakpoint as { sm: number; md: number; lg: number; xl: number; below_sm: number; tablet: number })[fluid],
    5
  );
};

// Styled Components

export const ColumnsOuter = styled.div.attrs({ shouldForwardProp })<ColumnsOuterProps>`
  width: 100%;
  ${composedResponsiveFunctions}
  ${fluidMediaQueryRules}
`;

export const ColumnsWrapper = styled.div.attrs({ shouldForwardProp })<
  ColumnsProps & Pick<MarcomMarginProps, 'marginX'> & Pick<FlexProps, 'alignItems'>
>`
  /* By default, columns are stacked on mobile */
  display: ${props => (props.stackOnMobile ? undefined : 'flex')};
  flex-wrap: ${props => (props.multiline ? 'wrap' : undefined)};
  justify-content: ${props => (props.centered ? 'center' : undefined)};
  align-items: ${props => props.alignItems || undefined};

  ${margin}

  ${props => props.theme.mediaQueries.sm} {
    display: flex;
  }
`;
