import React, { useState, useEffect, useCallback } from 'react';
import { CTALink } from '@components/CTALink';
import { JoykitIcon } from '@components/JoykitIcon';
import { useShopStorefrontContext } from '@utils/storefront/ShopStorefrontProvider';
import {
  StyledWrapper,
  CloseButton,
  StyledContent,
  StyledGridMobile,
  StyledGridDesktop,
  StyledCloudImage,
  StyledCTALink,
  StyledGridDesktopRow,
} from './ValuePropCards.styles';

interface ValuePropCardsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ValuePropCards: React.FC<ValuePropCardsProps> = ({ isOpen, onClose }) => {
  const { config } = useShopStorefrontContext();
  const [drawerClassName, setDrawerClassName] = useState('');

  const handleOnClose = useCallback(() => {
    setDrawerClassName('up');
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      setDrawerClassName('down');
    }
  }, [isOpen]);

  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = isOpen ? 'hidden' : 'scroll';
    }
    return () => {
      if (html) {
        html.style.overflowY = 'scroll';
      }
    };
  }, [isOpen]);

  return (
    <StyledWrapper className={drawerClassName}>
      <CloseButton id="valuePropsCardsCloseBtn" onClick={handleOnClose}>
        <JoykitIcon name="notAttending" color={'neutra6'} padding={2} />
      </CloseButton>
      <StyledContent>
        <StyledGridMobile>
          {config.valuePropCardsData.map((card, idx) => (
            <StyledCTALink
              key={`universal-value-props-mobile-${idx + card.id}`}
              id={`UniversalValuePropsMobile${card.id}`}
              variant="altPurple"
              href={card.link}
              aria-label={card.title}
              target="_blank"
              data-element-id="UniversalValueProps"
              hasSpan={false}
              tabIndex={isOpen ? 0 : -1}
            >
              <StyledCloudImage src={card.mobileImageUrl} optimization="default" isMainSrcShown={isOpen} />
            </StyledCTALink>
          ))}
        </StyledGridMobile>
        <StyledGridDesktop>
          <StyledGridDesktopRow first={true}>
            {config.valuePropCardsData.slice(0, 2).map((card, idx) => (
              <StyledCTALink
                key={`universal-value-props-${idx + card.id}`}
                id={`UniversalValueProps${card.id}`}
                variant="altPurple"
                href={card.link}
                aria-label={card.title}
                target="_blank"
                data-element-id="UniversalValueProps"
                hasSpan={false}
                tabIndex={isOpen ? 0 : -1}
              >
                <StyledCloudImage src={card.desktopImageUrl} optimization="default" isMainSrcShown={isOpen} />
              </StyledCTALink>
            ))}
          </StyledGridDesktopRow>
          <StyledGridDesktopRow first={false}>
            {config.valuePropCardsData.slice(2).map((card, idx) => (
              <StyledCTALink
                key={`universal-value-props-${idx + card.id}`}
                id={`UniversalValueProps${card.id}`}
                variant="altPurple"
                href={card.link}
                aria-label={card.title}
                target="_blank"
                data-element-id="UniversalValueProps"
                hasSpan={false}
                tabIndex={isOpen ? 0 : -1}
              >
                <StyledCloudImage src={card.desktopImageUrl} optimization="default" isMainSrcShown={isOpen} />
              </StyledCTALink>
            ))}
          </StyledGridDesktopRow>
        </StyledGridDesktop>
      </StyledContent>
    </StyledWrapper>
  );
};
