import { useCallback, useMemo } from 'react';
import { AcquisitionContext } from './types';
import { readAcquisitionContexts } from './acquisition-context-tracking';

const checkAcquisitionContextData = (dataDetails: AcquisitionContext, key: string, value: string) => {
  for (const item of dataDetails.data) {
    if (item.key === key && item.value === value) {
      return true;
    }
  }
  return false;
};

export const useAcquisitionContext = (): [AcquisitionContext[], (key: string, value: string) => boolean] => {
  const acquisitionContexts = readAcquisitionContexts();
  const checkAcquisitionContext = useCallback(
    (key: string, value: string) => acquisitionContexts.some(context => checkAcquisitionContextData(context, key, value)),
    [acquisitionContexts]
  );
  return [acquisitionContexts, checkAcquisitionContext];
};
