import React from 'react';
import { useScrollControllerContext } from '@context/ScrollControllerProvider/ScrollControllerProvider';
import { LazyLoadComponent, LazyLoadComponentProps } from 'react-lazy-load-image-component';
import { LazyLoadDefaultProps } from '@utils/lazyLoadDefaultProps';

const { defaultLazyLoadThreshold } = LazyLoadDefaultProps;

interface Props extends Omit<LazyLoadComponentProps, 'scrollPosition'> {}

export const LazyLoadWrapper: React.FC<Props> = ({ threshold, children, ...rest }) => {
  const { scrollPosition } = useScrollControllerContext() || {};
  return (
    <LazyLoadComponent threshold={threshold ?? defaultLazyLoadThreshold} scrollPosition={scrollPosition} {...rest}>
      {children}
    </LazyLoadComponent>
  );
};
