import React from 'react';
import { ABExperimenKey, experiments } from '@utils/experiments/experiments';

/**
 * Wrap a component in an experiment variant if the experiment is active
 * @param experimentKey
 * @returns
 */
export const headerWrapExperiment = (experimentKey?: ABExperimenKey) => {
  let Wrapper: React.FC = ({ children }) => <>{children}</>;
  if (experimentKey) {
    const ExperimentVariant = experiments[experimentKey].Variant.Component;
    if (ExperimentVariant) {
      Wrapper = ({ children }) => <ExperimentVariant>{children}</ExperimentVariant>;
    }
  }
  return Wrapper;
};
