import React, { useCallback } from 'react';
import { useImageRetry } from '@hooks/useImageRetry';
import { addError } from '@utils/logger';

interface ImageProps {
  alt: string;
  src: string;
}

export const Image: React.FC<ImageProps> = ({ alt, src }) => {
  const [retryCounter, retryOnError] = useImageRetry();

  const handleError = useCallback(() => {
    if (!retryOnError()) {
      addError(new Error('MediaError'), { src, alt, type: 'MediaError' });
    }
  }, [alt, retryOnError, src]);

  return <img key={`image-${retryCounter}`} onError={handleError} alt={alt} src={src} />;
};
