import React from 'react';
import { JoykitIcon } from '@components/JoykitIcon';
import { UniversalFooterLink } from './Footer.styles';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';

export const FooterSocial: React.FC = () => {
  return (
    <LazyLoadWrapper>
      <UniversalFooterLink id="footer2instagram" href="https://www.instagram.com/withjoy" variant="footer">
        <JoykitIcon name="instagramSocial" color="linkFooterHover" size={32} />
      </UniversalFooterLink>
      <UniversalFooterLink id="footer2pinterest" href="https://www.pinterest.com/joytheapp" variant="footer">
        <JoykitIcon name="pinterest" color="linkFooterHover" size={32} />
      </UniversalFooterLink>
      <UniversalFooterLink id="footer2facebook" href="https://www.facebook.com/joytheapp" variant="footer">
        <JoykitIcon name="facebook" color="linkFooterHover" size={32} />
      </UniversalFooterLink>
      <UniversalFooterLink id="footer2twitter" href="https://twitter.com/joytheapp" variant="footer">
        <JoykitIcon name="twitter" color="linkFooterHover" size={32} />
      </UniversalFooterLink>
      <UniversalFooterLink id="footer2twitterHash" href="https://twitter.com/joytheapp" variant="footer">
        #withjoy
      </UniversalFooterLink>
    </LazyLoadWrapper>
  );
};
