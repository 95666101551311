import { styled } from '@styledComponents';
import { Flex } from '@components/Flex';
import { Button } from '@components/Button';
import { CTALink } from '@components/CTALink';
import { PlaceholderCloudImage } from '@components/CloudImage';
import { themeColor, themeSpace, themeMediaQuery } from '@utils/styledTheme.util';

export const StyledWrapper = styled(Flex)`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100%;
  left: 0;
  top: -100%;
  overflow: auto;
  transition: top 0.5s cubic-bezier(0.35, 0, 0.35, 1);

  &.up {
    top: -100%;
  }

  &.down {
    top: 0;
  }
`;

export const CloseButton = styled(Button)<{ isClosed?: boolean }>`
  z-index: 102;
  padding: ${themeSpace(2)};
  outline: unset !important;
  color: unset;
  line-height: 24px;
  height: 40px;
  width: 40px;
  background: ${themeColor('white')};
  border: none;
  top: 0;
  right: 0;
  position: absolute;
  margin: ${themeSpace(3)} ${themeSpace(5)};
  &:hover,
  &:active {
    background-color: ${themeColor('white')};
  }
  ${themeMediaQuery('below_sm')} {
    display: flex;
  }
`;

export const StyledContent = styled(Flex)`
  flex: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${themeSpace(10)} ${themeSpace(7)};
  background-color: ${themeColor('white')};
  html {
    overflow: hidden;
  }
`;

export const StyledGridMobile = styled(Flex)`
  flex: 1;
  display: flex;
  width: 100%
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  gap: ${themeSpace(5)};
  ${themeMediaQuery('sm')} {
    display: none;
  }
`;

export const StyledGridDesktop = styled(Flex)`
  height: 100%;
  width: auto;
  column-gap: ${themeSpace(5)};
  justify-content: center;
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const StyledGridDesktopRow = styled(Flex)<{ first: boolean }>`
  height: 100%;
  flex-direction: column;
  justify-content: center;
  row-gap: ${themeSpace(5)};
  align-items: ${({ first }) => (first ? 'flex-end' : 'flex-start')};
  object-position: ${({ first }) => (first ? 'right' : 'left')} center;
`;

export const StyledCTALink = styled(CTALink)<{ tabIndex: number }>`
  width: auto;
  height: auto;
  overflow: hidden;
`;

export const StyledCloudImage = styled(PlaceholderCloudImage)`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
