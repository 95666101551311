import styled from 'styled-components';
import { variant } from 'styled-system';
import { TypographyVariant } from '@components/themes';
import { animationTransition } from '@utils/animationTransition';

export const CTALinkContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkWrapper = styled('a')<{ typography?: TypographyVariant | TypographyVariant[] }>`
  ${variant({
    prop: 'typography',
    scale: 'typography',
  })};
  ${variant({
    scale: 'links',
    variants: { primary: {} },
  })};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: ${animationTransition('color')};
  > span {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }

  svg {
    transition: ${animationTransition('transform')};
  }

  .chevron-right-icon {
    margin-left: 6px;
    padding-right: 1px;
  }

  &:hover,
  &:active {
    .chevron-right-icon {
      transform: scale(1.3);
      transform-origin: 50% 50%;
    }
  }

  &:active {
    .chevron-right-icon {
      transform: rotate(90deg);
    }
  }
  &:focus {
    outline: auto;
  }
`;
