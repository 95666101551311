import { css } from '@styledComponents';

// TODO: We noticed new Inter font variants while development. In future might need to move it to actual variants

export const InterFontFamily = 'Inter UI,sans-serif';

export const SmallTextStyles = css`
  font-family: ${InterFontFamily};
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
`;

export const SmallBoldTextStyles = css`
  font-family: ${InterFontFamily};
  font-size: 15px;
  line-height: 21px;
  font-weight: 600;
`;

export const MediumTextStyles = css`
  font-family: ${InterFontFamily};
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
`;

export const ButtonTextStyles = css`
  font-family: ${InterFontFamily};
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`;

export const LoadingTextStyles = css`
  font-family: ${InterFontFamily};
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
`;

export const MediumBoldTextStyles = css`
  font-family: ${InterFontFamily};
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
`;

export const Label2 = css`
  font-family: ${InterFontFamily};
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
`;

export const Display1Styles = css`
  font-family: ${InterFontFamily};
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
`;

export const Display2Styles = css`
  font-family: ${InterFontFamily};
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
`;

export const BodyMobileTextStyles = css`
  font-family: ${InterFontFamily};
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
`;

export const Hed4 = css`
  font-family: ${InterFontFamily};
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
`;

export const Body2 = css`
  font-family: ${InterFontFamily};
  font-size: 10px;
  line-height: 11px;
  font-weight: 600;
`;
