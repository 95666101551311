import React, { useEffect, useState } from 'react';
import { withWindow } from '@utils/withWindow';
import { ManageButton, BannerGlobalStyles } from './CookiesConsentBanner.styles';

export const CookiesConsentBannerStyles = BannerGlobalStyles;

export const CookiesConsentBanner: React.FC<{ id?: 'Desktop' | 'Mobile'; writersblock?: boolean }> = ({ id = 'Desktop', writersblock }) => {
  const [showManageCookies, setShowManageCookies] = useState(false);

  useEffect(() => {
    let timeoutHandle: NodeJS.Timeout;

    timeoutHandle = setTimeout(() => {
      let hasBanner = false;
      const bannerEl = document.getElementById('polaris-consent-widgets');
      if (bannerEl) {
        hasBanner = bannerEl.hasChildNodes();
      }
      setShowManageCookies(hasBanner);
    }, 3000);

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, []);

  const manageCookies = () => {
    withWindow(window => {
      window.polaris?.openConsentModal();
    });
  };

  return (
    <>
      {showManageCookies && (
        <>
          <ManageButton
            id={`footerManageCookies${id}`}
            variant="altPurpleSecondary"
            writersblock={writersblock}
            noreferrer
            noopener
            onClick={manageCookies}
          >
            Manage Cookies
          </ManageButton>
        </>
      )}
    </>
  );
};
