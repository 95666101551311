import { useCallback, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

interface MarcomRoutes {
  marcomRoutes: string[];
  isMarcomPage: (page: string) => boolean;
}

export function useGetMarcomRoutes(): MarcomRoutes {
  const { allSitePage } = useStaticQuery(
    graphql`
      query {
        allSitePage {
          edges {
            node {
              path
            }
          }
        }
      }
    `
  );

  const marcomRoutes: string[] = useMemo(() => allSitePage.edges.map((edge: { node: { path: string } }) => edge.node.path), [
    allSitePage.edges,
  ]);

  const isMarcomPage = useCallback(
    (page: string) => {
      // Added / at start and end of page to match with routes, if not exists.
      return marcomRoutes.includes(page.replace(/\/?/, '/').replace(/\/?$/, '/'));
    },
    [marcomRoutes]
  );

  return {
    marcomRoutes,
    isMarcomPage,
  };
}
