import { themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { styled } from '@styledComponents';
import { Box } from '@components/Box';
import { Text } from '@components/typography';
import { JoykitIcon } from '@components/JoykitIcon';

export const Container = styled(Box)<{ isHidden: boolean; isIos15Safari: boolean }>`
  display: none;
  ${themeMediaQuery('below_sm')} {
    position: fixed;
    bottom: ${({ isHidden }) => (isHidden ? '-87px' : '-1px')};
    width: 100%;
    padding: ${themeSpace(5)};
    padding-top: 9px;
    padding-bottom: ${({ isIos15Safari }) => (isIos15Safari ? '9px' : '36px')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    z-index: 100;
    transition: bottom 0.5s;
    @supports not (-webkit-touch-callout: none) {
      padding-bottom: 9px;
    }
  }
`;

export const NavIcon = styled(JoykitIcon)<{ isActive: Boolean }>`
  width: auto;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: auto;
    height: auto;
    color: ${({ isActive }) => (isActive ? '#7139BF' : '#717172')};
  }
`;

export const NavText = styled(Text)<{ isActive: Boolean; name: string }>`
  color: ${({ isActive }) => (isActive ? '#7139BF' : '#717172')};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.4px;
  padding-bottom: 1px;
`;
