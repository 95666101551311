import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  JoykitColor,
  TagName,
  TypographyMappingDesktopXlVariant,
  TypographyMappingMobileVariant,
  TypographyMappingTabletDesktopVariant,
} from '@components/themes';
import { color, compose, MarcomMarginProps, MarcomPaddingProps, margin, TextAlignProps, typography, variant } from 'styled-system';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { getMobileFontVariant, getTabletDesktopFontVariant } from './typographyMapping';

type TextAlignType = TextAlignProps['textAlign'];

export type TextProps = Readonly<{
  className?: string;
  variant: TypographyMappingDesktopXlVariant;
  tagName: TagName;
  style?: React.CSSProperties;
  textAlign?: TextAlignType;
  color?: JoykitColor;
  id?: string;
}> &
  MarcomMarginProps &
  MarcomPaddingProps;

const styledFunctions = compose(margin, color, typography);

const shouldForwardProp = createShouldForwardProp([...(styledFunctions.propNames || []), 'tagName']);

const TextComponent = styled.p.attrs({ shouldForwardProp })<{
  variant: readonly [
    TypographyMappingMobileVariant,
    TypographyMappingTabletDesktopVariant,
    TypographyMappingTabletDesktopVariant,
    TypographyMappingTabletDesktopVariant,
    TypographyMappingDesktopXlVariant
  ];
  textAlign: TextAlignType;
}>`
  ${variant({
    scale: 'typography',
  })}
  ${styledFunctions}
`;

export const Text: React.FC<TextProps> = ({ children, className, tagName, variant, style, color = 'purple6', textAlign, ...restProps }) => {
  // Automated mapping between desktop, tablet and mobile fonts according to https://www.figma.com/file/ihu7ErDxnCAKet42fEPrTa/JoyKit-Marcom-Website
  const variants = useMemo(() => {
    const tabletDesktopFontVariant = getTabletDesktopFontVariant(variant);
    return [getMobileFontVariant(variant), tabletDesktopFontVariant, tabletDesktopFontVariant, tabletDesktopFontVariant, variant] as const;
  }, [variant]);
  return (
    <TextComponent as={tagName} className={className} variant={variants} style={style} color={color} textAlign={textAlign} {...restProps}>
      {children}
    </TextComponent>
  );
};
