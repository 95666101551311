import { useEffect, useState } from 'react';
import { addAction } from '@utils/logger';
import { segment } from '@static/js/joy';
import globalWindow from './globals';
import { Integrations } from '@static/js/types';

const SCROLL = 'scroll';
const TRACKING_NAME = 'DistanceScrolled';
const TWO_SECONDS = 2000;

const document = globalWindow.document;

export const useScrollDepthTracking = () => {
  useEffect(() => {
    const instanceId = segment.generateGuid();
    let maxPageDepth = 0;
    let logScrollDepthHandle: number | undefined;

    function logScrollDepth() {
      if (!document) return;

      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      const currentDepth = Math.round((scrollTop / (scrollHeight - clientHeight)) * 100);

      if (currentDepth > maxPageDepth) {
        addAction(TRACKING_NAME, { instanceId, maxPageDepth: currentDepth });
      }

      logScrollDepthHandle = undefined;
    }

    function onScroll() {
      if (!logScrollDepthHandle) {
        logScrollDepthHandle = window.setTimeout(logScrollDepth, TWO_SECONDS);
      }
    }

    window.addEventListener(SCROLL, onScroll, { passive: true });

    return () => {
      window.removeEventListener(SCROLL, onScroll);
      window.clearTimeout(logScrollDepthHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
