import { FooterMainMenu } from 'src/layout/components/Footer/Footer.types';
import { HeaderMainMenu } from 'src/layout/components/Header/Header.types';

export enum CatalogStorefront {
  baby = 'baby',
  wedding = 'wedding',
}

export type ConfigByStorefront<T> = Record<CatalogStorefront, T>;

//// Grid Card
export type ValuePropCardsData = Array<{
  id: string;
  title: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  link: string;
  isExternal: boolean;
}>;

export type HeaderMainMenus = ReadonlyArray<HeaderMainMenu>;

export type FooterMainMenus = ReadonlyArray<FooterMainMenu>;
