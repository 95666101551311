import React from 'react';
import { Text } from '@components/typography';
import { JoykitIcon } from '@components/JoykitIcon';
import { StyledNavMenuLink } from './Header.styles';
import { HeaderNavMenuLinkProps } from './Header.types';
import { headerWrapExperiment } from './HeaderWrapExperiment';

const HeaderNavMenuLink: React.FC<HeaderNavMenuLinkProps> = ({
  idx,
  hasBlogs,
  id,
  title,
  handleOpenBlogMenu,
  to,
  href,
  target,
  experimentKey,
  isVisible,
  tabIndex,
}) => {
  const link = href ? href : to ? to : null;
  const Wrapper = headerWrapExperiment(experimentKey);
  return link ? (
    <Wrapper key={id}>
      <StyledNavMenuLink
        id={id}
        href={link}
        target={target}
        variant="navLink"
        onMouseEnter={event => {
          event.preventDefault();
          handleOpenBlogMenu(idx);
        }}
        isVisible={isVisible}
        tabIndex={tabIndex}
      >
        <Text variant="navChild36" tagName="span" style={{ lineHeight: '40px' }}>
          {title}
        </Text>
        {hasBlogs && <JoykitIcon name="forwardChevron" color="mono1" size={24} marginTop={2} />}
      </StyledNavMenuLink>
    </Wrapper>
  ) : null;
};

HeaderNavMenuLink.displayName = 'HeaderNavMenuLink';

export default HeaderNavMenuLink;
