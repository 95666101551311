import React, { useCallback, useEffect, useState, useRef, Fragment } from 'react';
import { Flex } from '@components/Flex';
import { Text } from '@components/typography';
import { CTALink } from '@components/CTALink';
import useLongPress from '@hooks/useLongPress';
import { JoykitColor } from '@components/themes';
import { headerData } from '@data-ts/shared/header';
import { JoykitIcon } from '@components/JoykitIcon';
import { getRootUrl } from '@utils/urlHelpers.util';
import { CloudImage } from '@components/CloudImage';
import HeaderNavMenuLink from './HeaderNavMenuLink';
import HeaderBlogMenuLink from './HeaderBlogMenuLink';
import { ParticleJoyLogo } from 'src/layout/ParticleJoyLogo';
import HeaderMobileNavMenuLink from './HeaderMobileNavMenuLink';
import { ButtonNoneStyled } from '@components/ButtonNoneStyled';
import { TOP_BANNER_HEIGHT } from 'src/layout/Layout.constants';
import { ValueProps } from 'src/layout/components/ValueProps';
import { UniversalNav } from 'src/layout/components/UniversalNav';
import { ValuePropCards } from 'src/layout/components/ValuePropCards';
import { abcSectionSectionId, footerPageId } from './Header.utils';
import { ReactComponent as JoyHeartIconSvg } from '@images/joy-heart.svg';
import { HeaderMainMenu, HeaderMenuID, HeaderProps, HeaderSubMenu } from './Header.types';
import { CONFIG_BY_STOREFRONT, useShopStorefrontContext } from '@utils/storefront/ShopStorefrontProvider';
import {
  StyledGetStartedButton,
  HeaderSpacingStub,
  MobileFindButton,
  MobileGetStartedButton,
  MobileLoginButton,
  NavMenuDesktopDropShell,
  NavMenuDesktopDropShellWrapper,
  NavMenuDesktopTopBar,
  NavMenuMobileDropShell,
  NavMenuMobileHamburgerImgWrapper,
  NavMenuMobileTopBar,
  NavMenuWrapperMobile,
  StyledHeader,
  StyledNavMenuLgWrapper,
  ToggleNavParent,
  HamburgerButton,
  topHeaderLinkHeroTransitionClassName,
  MobHeaderContent,
  MobGetStartedButton,
  StyledNavSubMenuLgWrapper,
  StyledNavMenuLinkWrapper,
  StyledNavBlogMenuLink,
  StyledNavMenuDesktopTopBarLeft,
  StyledNavMenuDesktopTopBarRight,
  NavMenuMobileDropShellContainer,
  DisplayAboveMobile,
  DisplayOnMobile,
} from './Header.styles';
import { CatalogStorefront, HeaderMainMenus } from '@utils/storefront/storefront.types';

const header = headerData.header;

const Header = React.forwardRef<HTMLElement, HeaderProps>(
  (
    {
      isTransparent = false,
      scrollThreshold: defaultScrollThreshold = 0,
      headerBackgroundColor = 'navbar',
      transparentJoyLogoColor = 'mono7',
      transparentHeaderLinkVariant = 'humanaLink',
      transparentHeaderButtonVariant = 'yellowPrimary',
      hideValueProps = false,
      page,
      handleGetStarted,
      handleHamburgerLongPress,
    },
    ref
  ) => {
    const handleHamburgerLongPressDefault = () => {};
    const { config, currentStorefront } = useShopStorefrontContext();
    const hamburgerLongPress = useLongPress(handleHamburgerLongPress ?? handleHamburgerLongPressDefault);
    const [openedMenu, setOpened] = useState<HeaderMenuID | null>(null);
    const [isOpening, setIsOpening] = useState(false);
    const [isScrollCollapsed, setIsScrollCollapsed] = useState(false);
    const [isAnnounceCollapsed, setIsAnnounceCollapsed] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [selectedMenuId, setSelectedMenuId] = useState(0);
    const [selectedBlogMenuId, setSelectedBlogMenuId] = useState<number>(-1);
    const [isOpenValuePropCards, setIsOpenValuePropCards] = useState(false);
    const mainMenuRef = useRef<HTMLDivElement>(null);
    const mainMenuContainerRef = useRef<HTMLDivElement>(null);
    const mobileMenuPanelRef = useRef<HTMLDivElement>(null);

    const mainMenuOptions = config.mainMenuOptions;

    // To get all menus except current store front to make it available on dom
    const catalogStoreFrontKeys = Object.values(CatalogStorefront);
    const allOtherMainMenuOptions: HeaderMainMenus[] = catalogStoreFrontKeys.reduce((acc: HeaderMainMenus[], key: CatalogStorefront) => {
      if (key !== currentStorefront) {
        acc.push(CONFIG_BY_STOREFRONT[key].mainMenuOptions);
      }
      return acc;
    }, []);

    const blogMenus: ReadonlyArray<Omit<HeaderSubMenu, 'blogMenus'>> =
      mainMenuOptions[selectedMenuId]?.subMenus[selectedBlogMenuId]?.blogMenus ?? [];
    const isFindACouplePage = page === 'find';

    const actualIsTransparent = openedMenu || isScrollCollapsed ? false : isTransparent;
    let buttonVariant = actualIsTransparent ? transparentHeaderButtonVariant : 'primary';
    if ((page === 'wedding-website' || page === 'home') && !actualIsTransparent) {
      buttonVariant = 'primary';
    }

    const linkVariant = actualIsTransparent ? transparentHeaderLinkVariant : 'primary';
    const logoColor: JoykitColor = actualIsTransparent && !openedMenu ? transparentJoyLogoColor : 'purple6';

    const handleOpenBlogMenu = useCallback((idx: number) => setSelectedBlogMenuId(idx), []);

    const toggleValueProps = useCallback(() => setIsOpenValuePropCards(prev => !prev), []);

    const handleOpenBlogMenuMobile = useCallback(
      (idxParent: number, idx: number) => {
        setSelectedMenuId(idxParent);
        setSelectedBlogMenuId(idx);
        mobileMenuPanelRef?.current?.scrollIntoView({ behavior: 'smooth' });
      },
      [mobileMenuPanelRef]
    );

    const setOpenedMenu = (menu: HeaderMenuID | null) => {
      if (menu === openedMenu) return;

      if (menu === HeaderMenuID.mobileNavMenu || openedMenu === HeaderMenuID.mobileNavMenu) {
        setOpened(menu);
      } else {
        if (menu && Object.values(HeaderMenuID).includes(menu)) {
          setIsOpening(true);
          setOpened(menu);
        } else {
          setIsOpening(false);
          setTimerId(
            setTimeout(() => {
              setOpened(menu);
            }, 500)
          );
        }
      }
    };

    const toggleOpenedMenu = (menu: HeaderMenuID, idx: number, canClose: boolean = true) => {
      if (openedMenu === menu && canClose) {
        setOpenedMenu(null);
        setSelectedMenuId(0);
      } else {
        setOpenedMenu(menu);
        setSelectedMenuId(idx);
      }
    };

    const GetStartedCTA = () => {
      const { getStarted, getStartedV2 } = header;
      return page === 'registry' ? <>{getStartedV2}</> : <>{getStarted}</>;
    };

    const getMobileMenuButtons = ({ idSuffix = '', marginBottom = 0 }) => {
      return (
        <>
          <Flex flexDirection="column" marginBottom={marginBottom}>
            {idSuffix !== 'Top' && (
              <MobileGetStartedButton id={`menu2createMobile${idSuffix}`} page={page} onClick={handleGetStarted}>
                {page === 'registry' ? header.getStartedV2 : header.getStarted}
              </MobileGetStartedButton>
            )}
            <MobileLoginButton
              id={`menu2meMobile${idSuffix}`}
              forwardedAs={'a'}
              href={getRootUrl('/login')}
              variant={'secondary'}
              onClick={handleGetStarted}
            >
              {header.login}
            </MobileLoginButton>
            <MobileFindButton id={`menu2findMobileTop${idSuffix}`} href="/find">
              {header.findAnEvent}
            </MobileFindButton>
          </Flex>
        </>
      );
    };

    useEffect(() => {
      return () => {
        if (timerId) {
          clearTimeout(timerId);
        }
      };
    }, [timerId]);

    useEffect(() => {
      const onWindowScroll = () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll > TOP_BANNER_HEIGHT && !isAnnounceCollapsed) {
          setIsAnnounceCollapsed(true);
        } else if (currentScroll <= TOP_BANNER_HEIGHT && isAnnounceCollapsed) {
          setIsAnnounceCollapsed(false);
        }

        const scrollThreshold = ((162 - 80) * 3) / 2;
        if (currentScroll > scrollThreshold && !isScrollCollapsed) {
          setIsScrollCollapsed(true);
        } else if (currentScroll <= scrollThreshold && isScrollCollapsed) {
          setIsScrollCollapsed(false);
        }

        const waterfallSection = document.getElementById('Waterfall_CTA');
        const abcSection = document.getElementById(abcSectionSectionId({ page }));
        const footer = document.getElementById(footerPageId({ page }));

        if (
          (waterfallSection && currentScroll >= waterfallSection.offsetTop) ||
          (abcSection && currentScroll >= abcSection.offsetTop) ||
          (footer && currentScroll >= footer.offsetTop)
        ) {
          if (!hidden) {
            setHidden(true);
          }
        } else {
          if (hidden) {
            setHidden(false);
          }
        }
      };
      // use passive listeners here:
      // https://web.dev/uses-passive-event-listeners/?utm_source=lighthouse&utm_medium=devtools
      window.addEventListener('scroll', onWindowScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', onWindowScroll);
      };
    }, [defaultScrollThreshold, setHidden, hidden, isScrollCollapsed, page, isAnnounceCollapsed]);

    useEffect(() => {
      const html = document.querySelector('html');
      if (html) {
        html.style.overflowY = openedMenu === HeaderMenuID.mobileNavMenu ? 'hidden' : 'scroll';
      }
      return () => {
        if (html) {
          html.style.overflowY = 'scroll';
        }
      };
    }, [openedMenu]);

    return (
      <>
        <StyledHeader
          id="page_header"
          ref={ref}
          isTransparent={actualIsTransparent}
          backgroundColor={headerBackgroundColor}
          isMenuOpen={!!openedMenu}
          onMouseLeave={event => {
            event.preventDefault();
            setSelectedBlogMenuId(-1);
            if (openedMenu !== HeaderMenuID.mobileNavMenu) setOpenedMenu(null);
          }}
          hiddenMenu={hidden}
          isScrollCollapsed={isScrollCollapsed}
        >
          <>
            <DisplayAboveMobile>
              <UniversalNav page={page} />
            </DisplayAboveMobile>
            {openedMenu !== HeaderMenuID.mobileNavMenu && !hideValueProps && <ValueProps toggleValueProps={toggleValueProps} />}
            <NavMenuDesktopTopBar hiddenMenu={hidden} isScrollCollapsed={isScrollCollapsed}>
              <StyledNavMenuDesktopTopBarLeft className="nav-menu-left" ref={mainMenuContainerRef}>
                <ParticleJoyLogo
                  id="desktop-joy-logo"
                  variant={linkVariant}
                  logoColor={logoColor}
                  isScrollCollapsed={isScrollCollapsed}
                  ariaLabel="Desktop Joy Logo"
                />
                <Flex style={{ columnGap: '32px' }}>
                  {mainMenuOptions.map((mainMenuOption, idx) => (
                    <Flex key={`main-menu-${idx}`} ref={mainMenuRef}>
                      <CTALink
                        id={mainMenuOption.id}
                        variant={linkVariant}
                        href="#"
                        onMouseEnter={event => {
                          event.preventDefault();
                          toggleOpenedMenu(mainMenuOption.menuId, idx, false);
                        }}
                        onClick={event => {
                          event.preventDefault();
                          toggleOpenedMenu(mainMenuOption.menuId, idx);
                        }}
                        css={{ zIndex: 1 }}
                      >
                        <ToggleNavParent
                          variant="navParent18"
                          tagName="span"
                          isActive={openedMenu === mainMenuOption.menuId}
                          className={topHeaderLinkHeroTransitionClassName}
                        >
                          <span style={{ whiteSpace: 'nowrap' }}>{mainMenuOption.title}</span>
                          <JoyHeartIconSvg />
                        </ToggleNavParent>
                      </CTALink>
                    </Flex>
                  ))}
                </Flex>
              </StyledNavMenuDesktopTopBarLeft>
              <StyledNavMenuDesktopTopBarRight className="nav-menu-right">
                <CTALink id="menu2login" href={getRootUrl('/login')} variant={linkVariant} onClick={handleGetStarted}>
                  <ToggleNavParent variant="navParent18" tagName="span" className={topHeaderLinkHeroTransitionClassName}>
                    <span>{header.login}</span>
                  </ToggleNavParent>
                </CTALink>
                {!isFindACouplePage && (
                  <StyledGetStartedButton id="menu2create" page={page} variant={buttonVariant} onClick={handleGetStarted}>
                    <GetStartedCTA />
                  </StyledGetStartedButton>
                )}
              </StyledNavMenuDesktopTopBarRight>
            </NavMenuDesktopTopBar>
            <NavMenuDesktopDropShellWrapper isOpen={!!openedMenu} isOpening={isOpening} isScrollCollapsed={isScrollCollapsed}>
              <NavMenuDesktopDropShell>
                <>
                  <StyledNavMenuLgWrapper>
                    <StyledNavMenuLinkWrapper flexDirection="column">
                      {mainMenuOptions.map((mainMenuItem: HeaderMainMenu, idxParent: number) => (
                        <Fragment key={`header-nav-parent-menu-${idxParent}`}>
                          {mainMenuItem.subMenus.map((navItem: HeaderSubMenu, idx: number) => (
                            <HeaderNavMenuLink
                              key={`header-nav-menu-${idxParent + idx}`}
                              idx={idx}
                              hasBlogs={navItem.blogMenus ? true : false}
                              id={navItem.id}
                              title={navItem.title}
                              to={navItem.to}
                              href={navItem.href}
                              target={navItem.target}
                              experimentKey={navItem.experimentKey}
                              handleOpenBlogMenu={handleOpenBlogMenu}
                              isVisible={idxParent === selectedMenuId}
                              tabIndex={openedMenu && selectedMenuId === idxParent ? 0 : -1}
                            />
                          ))}
                        </Fragment>
                      ))}
                    </StyledNavMenuLinkWrapper>

                    {mainMenuOptions.map((mainMenuItem: HeaderMainMenu, idxParent: number) => (
                      <Fragment key={`header-blog-parent-menu-${idxParent}`}>
                        {mainMenuItem.subMenus.map((navItem: HeaderSubMenu, idx: number) => (
                          <Fragment key={`header-blog-main-menu-${idxParent + idx}`}>
                            {navItem?.blogMenus && navItem?.blogMenus?.length > 0 && (
                              <StyledNavSubMenuLgWrapper>
                                <StyledNavBlogMenuLink flexDirection="column">
                                  {navItem.blogMenus?.slice(0, 6).map((blogMenu, idxBlog) => (
                                    <HeaderBlogMenuLink
                                      key={`header-blog-menu-${idxParent + idx + idxBlog}`}
                                      idx={idxBlog}
                                      id={blogMenu.id}
                                      title={blogMenu.title}
                                      to={blogMenu.to}
                                      href={blogMenu.href}
                                      target={blogMenu.target}
                                      experimentKey={blogMenu.experimentKey}
                                      isVisible={selectedMenuId === idxParent && selectedBlogMenuId === idx}
                                      tabIndex={openedMenu && selectedMenuId === idxParent && selectedBlogMenuId === idx ? 0 : -1}
                                    />
                                  ))}
                                </StyledNavBlogMenuLink>
                                <StyledNavBlogMenuLink flexDirection="column">
                                  {navItem.blogMenus?.slice(6).map((blogMenu, idxBlog) => (
                                    <HeaderBlogMenuLink
                                      key={`header-blog-menu-${idxParent + idx + idxBlog}-${idxBlog}`}
                                      idx={idx}
                                      id={blogMenu.id}
                                      title={blogMenu.title}
                                      to={blogMenu.to}
                                      href={blogMenu.href}
                                      target={blogMenu.target}
                                      experimentKey={blogMenu.experimentKey}
                                      isVisible={selectedMenuId === idxParent && selectedBlogMenuId === idx}
                                      tabIndex={openedMenu && selectedMenuId === idxParent && selectedBlogMenuId === idx ? 0 : -1}
                                    />
                                  ))}
                                </StyledNavBlogMenuLink>
                              </StyledNavSubMenuLgWrapper>
                            )}
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}

                    {/* To get all menus except current store front to make it available on dom */}
                    {allOtherMainMenuOptions.map((mainMenu: HeaderMainMenus, idxMain) => (
                      <Fragment key={`header-nav-storefront-menu-other-${idxMain}`}>
                        {mainMenu.map((mainMenuItem: HeaderMainMenu, idxParent: number) => (
                          <Fragment key={`header-nav-main-parent-menu-other-${idxMain + idxParent}`}>
                            {mainMenuItem.subMenus.map((navItem: HeaderSubMenu, idx: number) => (
                              <Fragment key={`header-nav-parent-menu-other-${idxMain + idxParent + idx}`}>
                                <HeaderNavMenuLink
                                  key={`header-nav-menu-other-${idxMain + idxParent + idx}`}
                                  idx={idx}
                                  hasBlogs={navItem.blogMenus ? true : false}
                                  id={navItem.id}
                                  title={navItem.title}
                                  to={navItem.to}
                                  href={navItem.href}
                                  target={navItem.target}
                                  experimentKey={navItem.experimentKey}
                                  handleOpenBlogMenu={handleOpenBlogMenu}
                                  tabIndex={-1}
                                />
                                {navItem?.blogMenus && navItem?.blogMenus?.length > 0 && (
                                  <Fragment key={`header-blog-parent-menu-other-${idxParent}`}>
                                    {navItem.blogMenus?.slice(0, 6).map((blogMenu, idxBlog) => (
                                      <HeaderBlogMenuLink
                                        key={`header-blog-menu-other-${idxParent + idx + idxBlog}`}
                                        idx={idxBlog}
                                        id={blogMenu.id}
                                        title={blogMenu.title}
                                        to={blogMenu.to}
                                        href={blogMenu.href}
                                        target={blogMenu.target}
                                        experimentKey={blogMenu.experimentKey}
                                        tabIndex={-1}
                                      />
                                    ))}
                                  </Fragment>
                                )}
                              </Fragment>
                            ))}
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                  </StyledNavMenuLgWrapper>
                </>
              </NavMenuDesktopDropShell>
            </NavMenuDesktopDropShellWrapper>
          </>
          <NavMenuWrapperMobile isScrollCollapsed={isAnnounceCollapsed}>
            {!openedMenu && (
              <NavMenuMobileTopBar>
                {!isScrollCollapsed && (
                  <MobHeaderContent alignItems="center" justifyContent="space-between" paddingX={7}>
                    <ParticleJoyLogo
                      id="mobile-joy-logo"
                      ariaLabel="Joy Logo"
                      variant={linkVariant}
                      logoColor={logoColor}
                      isScrollCollapsed={isScrollCollapsed}
                    />
                    <ButtonNoneStyled
                      {...hamburgerLongPress}
                      id="mobile-nav-hamburger-menu"
                      onClick={() => setOpenedMenu(openedMenu ? null : HeaderMenuID.mobileNavMenu)}
                      aria-label="Hamburger Menu"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <JoykitIcon name="hamburger" color={logoColor} size={24} />
                    </ButtonNoneStyled>
                  </MobHeaderContent>
                )}
                {isScrollCollapsed && (
                  <MobHeaderContent alignItems="center" justifyContent={isFindACouplePage ? 'flex-end' : 'space-between'} paddingX={7}>
                    {!isFindACouplePage && (
                      <MobGetStartedButton id="menu2getStartedMobile" variant="secondary" page={page} onClick={handleGetStarted}>
                        <GetStartedCTA />
                      </MobGetStartedButton>
                    )}
                    <HamburgerButton
                      {...hamburgerLongPress}
                      id="mobile-nav-hamburger-menu-collapsed"
                      onClick={() => setOpenedMenu(openedMenu ? null : HeaderMenuID.mobileNavMenu)}
                      aria-label="Hamburger Menu"
                    >
                      <NavMenuMobileHamburgerImgWrapper variant={openedMenu ? 'primary' : linkVariant}>
                        <JoykitIcon name="hamburger" color={logoColor} size={24} />
                      </NavMenuMobileHamburgerImgWrapper>
                    </HamburgerButton>
                  </MobHeaderContent>
                )}
              </NavMenuMobileTopBar>
            )}

            <NavMenuMobileDropShell isOpen={!!openedMenu}>
              {openedMenu === HeaderMenuID.mobileNavMenu && (
                <>
                  <DisplayOnMobile>
                    <UniversalNav page={page} />
                  </DisplayOnMobile>
                  <NavMenuMobileDropShellContainer ref={mobileMenuPanelRef} flexDirection="column" paddingX={7} paddingTop={6}>
                    <Flex justifyContent="space-between">
                      <CTALink id="mobile-nav-panel" href={config.homePageUrl} aria-label="Joy Logo">
                        <CloudImage src="common/brand/png_mono_1/logo_sm.png" alt="Joy Logo" width={45} isEagerLoading />
                      </CTALink>
                      <ButtonNoneStyled id="mobile-nav-panel-close" onClick={() => setOpenedMenu(null)} aria-label="Close Menu Panel">
                        <JoykitIcon name="close" color="mono1" size={32} />
                      </ButtonNoneStyled>
                    </Flex>
                    {getMobileMenuButtons({ idSuffix: 'Top' })}
                    <Flex
                      flexDirection="column"
                      style={{
                        rowGap: '24px',
                        visibility: openedMenu === HeaderMenuID.mobileNavMenu ? 'visible' : 'hidden',
                      }}
                    >
                      {blogMenus.length > 0 ? (
                        <>
                          {blogMenus.length > 0 && (
                            <StyledNavSubMenuLgWrapper>
                              <Flex flexDirection="column" style={{ rowGap: '24px' }}>
                                <ButtonNoneStyled
                                  id="mobile-nav-panel-back"
                                  onClick={() => handleOpenBlogMenuMobile(-1, -1)}
                                  style={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}
                                  aria-label="Back To Main Menu"
                                >
                                  <JoykitIcon name="backChevron" color="mono1" size={16} />
                                  <Text variant="featureHed17" tagName="span" color="mono12">
                                    {header.backToAll} {currentStorefront.charAt(0).toUpperCase() + currentStorefront.slice(1)}
                                  </Text>
                                </ButtonNoneStyled>
                                <Text variant="navChild36" tagName="span" color="mono1">
                                  {mainMenuOptions[selectedMenuId]?.subMenus[selectedBlogMenuId]?.title}
                                </Text>
                              </Flex>
                              <StyledNavBlogMenuLink flexDirection="column">
                                {blogMenus.map((blogMenu, idx) => (
                                  <HeaderBlogMenuLink
                                    key={`header-blog-menu-mobile-${idx}`}
                                    idx={idx}
                                    id={blogMenu.id}
                                    title={blogMenu.title}
                                    to={blogMenu.to}
                                    href={blogMenu.href}
                                    target={blogMenu.target}
                                    experimentKey={blogMenu.experimentKey}
                                    isVisible={true}
                                  />
                                ))}
                              </StyledNavBlogMenuLink>
                            </StyledNavSubMenuLgWrapper>
                          )}
                        </>
                      ) : (
                        <>
                          {mainMenuOptions.map((mainMenuOption, idxParent) => (
                            <Flex key={`main-menu-mobile-${idxParent}`} flexDirection="column">
                              <Text variant="featureHed17" tagName="span" color="mono1" marginBottom={3}>
                                {mainMenuOption.title}
                              </Text>
                              {mainMenuOption.subMenus.map((navItem: HeaderSubMenu, idx: number) => (
                                <HeaderMobileNavMenuLink
                                  key={`header-blog-mobile-menu-${idx}`}
                                  idxParent={idxParent}
                                  idx={idx}
                                  id={navItem.id}
                                  hasBlogs={navItem.blogMenus ? true : false}
                                  title={navItem.mobileTitle ? navItem.mobileTitle : navItem.title}
                                  to={navItem.to}
                                  href={navItem.href}
                                  target={navItem.target}
                                  experimentKey={navItem.experimentKey}
                                  handleOpenBlogMenu={handleOpenBlogMenuMobile}
                                  isVisible={true}
                                />
                              ))}
                            </Flex>
                          ))}
                        </>
                      )}
                    </Flex>
                    {getMobileMenuButtons({ marginBottom: 7 })}
                  </NavMenuMobileDropShellContainer>
                </>
              )}
            </NavMenuMobileDropShell>
          </NavMenuWrapperMobile>
        </StyledHeader>

        {/* stub element for correct spacing depending on isTransparent*/}
        {!isTransparent && <HeaderSpacingStub />}
        <ValuePropCards isOpen={isOpenValuePropCards} onClose={toggleValueProps} />
      </>
    );
  }
);

Header.displayName = 'Header';

export { Header };
