import { createContext } from '@utils/createContext';
import { setRef } from '@utils/setRef';
import React, { useCallback, useReducer, useRef, useState } from 'react';

type IframeMessageBusContext = {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  attachIframeRef: (node: HTMLIFrameElement | null) => void;
  hasIframeInitialized: boolean;
  setHasIframeInitialized: (next: boolean) => void;
};

const [Provider, useIframeMessageBusContext] = createContext<IframeMessageBusContext>({ name: 'IframeMessageBus' });

const IframeMessageBusProvider = ({ children }: { children: React.ReactNode }) => {
  const [, tick] = useReducer(x => x + 1, 0);
  const [hasIframeInitialized, setHasIframeInitialized] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const attachIframeRef = useCallback(
    (node: HTMLIFrameElement | null) => {
      setRef(iframeRef, node);
      if (!node) {
        setHasIframeInitialized(false);
      }
      tick();
    },
    [tick]
  );

  return <Provider value={{ iframeRef, attachIframeRef, hasIframeInitialized, setHasIframeInitialized }}>{children}</Provider>;
};
export { IframeMessageBusProvider, useIframeMessageBusContext };
