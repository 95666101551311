import React, { useCallback } from 'react';
import { cloudAssetUrl, DexecureImageOptions } from '@utils/cloudAssetUrl.util';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useScrollControllerContext } from '@context/ScrollControllerProvider/ScrollControllerProvider';
import { LazyLoadDefaultProps } from '@utils/lazyLoadDefaultProps';
import { useImageRetry } from '@hooks/useImageRetry';
import { addError } from '@utils/logger';

type FetchPriority = 'high' | 'low' | 'auto';

interface BaseImageProps extends DexecureImageOptions {
  src: string;
  id?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: React.CSSProperties;
  isEagerLoading?: boolean;
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

interface ImageProps extends BaseImageProps {
  alt: string;
  isDecor?: false;
  onErrorImg?: string;
  fetchPriority?: FetchPriority;
}

/**
 * Decorative images don't need "alt" attribute to be set
 */
interface DecorImageProps extends BaseImageProps {
  alt?: never;
  isDecor?: true;
  onErrorImg?: string;
  fetchPriority?: FetchPriority;
}

// Extend the existing LazyLoadImageProps interface
declare module 'react-lazy-load-image-component' {
  interface LazyLoadImageProps {
    fetchpriority?: FetchPriority; // Add fetchPriority property
  }
}

const { isImagesEagerByDefault, defaultLazyLoadThreshold } = LazyLoadDefaultProps;
export const defaultBase64Placeholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=';

export const BaseImage: React.FC<ImageProps | DecorImageProps> = ({
  alt = '',
  id,
  src,
  width,
  height,
  className,
  style,
  isEagerLoading,
  isDecor, // Excluding from restProps, as we're not using this prop anywhere just yet.
  onErrorImg,
  fetchPriority = 'auto',
  ...restProps
}) => {
  const [retryCounter, retryOnError] = useImageRetry();

  const onError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (!retryOnError()) {
        addError(new Error('MediaError'), { src, alt, type: 'MediaError' });
        if (onErrorImg) {
          e.currentTarget.onerror = null;
          e.currentTarget.src = onErrorImg;
        }
      }
    },
    [alt, onErrorImg, retryOnError, src]
  );

  const { scrollPosition } = useScrollControllerContext() || {};

  return (
    <LazyLoadImage
      visibleByDefault={isEagerLoading ?? isImagesEagerByDefault}
      alt={alt}
      id={id}
      key={`base-image-${retryCounter}-${id}`}
      threshold={defaultLazyLoadThreshold}
      {...{ src, width, height, className, style }}
      {...restProps}
      scrollPosition={scrollPosition}
      onError={onError}
      fetchpriority={fetchPriority}
    />
  );
};

export const CloudImage: React.FC<ImageProps | DecorImageProps> = ({
  src,
  resizeWidth,
  resizeHeight,
  mobileResizeWidth,
  mobileResizeHeight,
  isResizeDisabled,
  optimization,
  onErrorImg,
  isDecor, // Excluding from restProps, as we're not using this prop anywhere just yet.
  fetchPriority,
  ...restProps
}) => {
  return (
    <BaseImage
      {...restProps}
      src={cloudAssetUrl(src, { resizeWidth, resizeHeight, mobileResizeWidth, mobileResizeHeight, isResizeDisabled, optimization })}
      onErrorImg={
        onErrorImg &&
        cloudAssetUrl(onErrorImg, { resizeWidth, resizeHeight, mobileResizeWidth, mobileResizeHeight, isResizeDisabled, optimization })
      }
      fetchPriority={fetchPriority}
    />
  );
};

interface PlaceholderBaseImageProps extends Omit<BaseImageProps, 'isEagerLoading'> {
  /**
   * src of the placeholder
   * @default - base64 encoded image (transparent 1px x 1px)
   */
  placeholderSrc?: string;
  /**
   * PlaceholderCloudImage component is loading placeholder eagerly and replaces with main src when isMainSrcShown set to true
   */
  isMainSrcShown?: boolean;
}

interface PlaceholderImageProps extends PlaceholderBaseImageProps {
  alt: string;
  isDecor?: false;
}

/**
 * Decorative images don't need "alt" attribute to be set
 */
interface PlaceholderDecorImageProps extends PlaceholderBaseImageProps {
  alt?: never;
  isDecor?: true;
}

/**
 * This component shows placeholder by default and eagerly loaded
 *
 * Default LazyLoadImage placeholder capabilities doesn't fit for all our needs (e.g. some animations initialization, carousels images)
 * and this component should be used for these specific needs.
 *
 * This components acts similarly to eagerly loaded CloudImage, but with placeholder loading and
 * manual control over when main src should be loaded(isMainSrcShown)
 */
export const PlaceholderCloudImage: React.FC<PlaceholderImageProps | PlaceholderDecorImageProps> = ({
  src,
  resizeWidth,
  resizeHeight,
  mobileResizeWidth,
  mobileResizeHeight,
  isResizeDisabled,
  optimization,
  isMainSrcShown,
  placeholderSrc,
  isDecor, // Excluding from restProps, as we're not using this prop anywhere just yet.
  ...restProps
}) => {
  const actualSrc = isMainSrcShown
    ? cloudAssetUrl(src, { resizeWidth, resizeHeight, mobileResizeWidth, mobileResizeHeight, isResizeDisabled, optimization })
    : placeholderSrc
    ? cloudAssetUrl(placeholderSrc)
    : defaultBase64Placeholder;
  return <BaseImage {...restProps} isEagerLoading src={actualSrc} />;
};
