import React, { useContext, useMemo } from 'react';
import { ColumnGap } from './Columns.types';

export type ColumnsContextType = Readonly<{
  columnGap: ColumnGap;
  stackOnMobile: boolean;
}>;

export const ColumnsContext = React.createContext<ColumnsContextType | undefined>(undefined);

export const ColumnsContextProvider: React.FC<{ columnGap: ColumnGap; stackOnMobile: boolean }> = ({
  children,
  columnGap,
  stackOnMobile,
}) => {
  const value = useMemo(() => {
    return {
      columnGap,
      stackOnMobile,
    };
  }, [columnGap, stackOnMobile]);
  return <ColumnsContext.Provider value={value}>{children}</ColumnsContext.Provider>;
};

export const useColumnsContext = () => {
  const context = useContext(ColumnsContext);
  if (context === undefined) {
    throw new Error('useColumnsContext must be used within a ColumnsContextProvider');
  }
  return context;
};
