import styled from 'styled-components';
import { color, MarcomColorProps, MarcomSpaceProps, space } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

export interface BoxProps extends MarcomSpaceProps, MarcomColorProps {}

// beware of this issue https://github.com/styled-system/styled-system/issues/158
const Box = styled.div.attrs({ shouldForwardProp })<BoxProps>`
  ${space}
  ${color}
`;

Box.displayName = 'Box';

export { Box };
