import { Link } from 'gatsby';
import { PropsWithChildren, useMemo } from 'react';
import { useGetMarcomRoutes } from './useGetMarcomRoutes';

interface GatsbyLinkProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedAs?: 'a' | 'button' | React.ComponentType<any>;
  href?: string;
  target?: string;
  to?: string;
}

export function useGatsbyLinkProps<T extends GatsbyLinkProps>(hocProps: T): [PropsWithChildren<T & { as?: T['forwardedAs'] }>] {
  const { isMarcomPage } = useGetMarcomRoutes();
  const { forwardedAs, href, target, to, ...restProps } = hocProps;

  const props = useMemo(() => {
    let props = {} as PropsWithChildren<T & { as?: T['forwardedAs'] }>;

    if (href) {
      const isMarcomPageRoute = isMarcomPage(href);

      if (isMarcomPageRoute) {
        props.to = href;
        props.as = Link;
        props.forwardedAs = Link;
      }

      if (!isMarcomPageRoute) {
        props.href = href;
        props.target = target;
        props.forwardedAs = forwardedAs;
      }
    } else {
      props.forwardedAs = forwardedAs;
    }
    return { ...restProps, ...props };
  }, [forwardedAs, href, isMarcomPage, restProps, target]);

  return [props];
}
