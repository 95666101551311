import React, { createContext, useContext, useEffect, useState } from 'react';
import ScrollMagic from 'scrollmagic';
import { ScrollPosition } from 'react-lazy-load-image-component';

interface ScrollControllerContextValue {
  scrollController?: ScrollMagic.Controller;
  scrollPosition?: ScrollPosition;
}

const ScrollControllerContext = createContext<Maybe<ScrollControllerContextValue>>(undefined);

export const useScrollControllerContext = () => {
  const context = useContext(ScrollControllerContext);
  if (context === undefined) {
    throw new Error('useScrollControllerContext must be used within a ScrollControllerProvider');
  }

  return context;
};

export const ScrollControllerProvider: React.FC<{ scrollPosition: ScrollPosition }> = ({ children, scrollPosition }) => {
  const [state, setState] = useState<Maybe<ScrollControllerContextValue>>(null);
  useEffect(() => {
    const scrollController = new ScrollMagic.Controller();
    setState({ scrollController, scrollPosition });
    const timeout = setTimeout(() => {
      scrollController?.update();
    }, 500);
    return () => {
      // check if all Tweens destroyed from gsap(+check gsap plugin for scrollmagic)
      scrollController.destroy(true);
      setState(null);
      clearTimeout(timeout);
    };
  }, [scrollPosition]);

  return <ScrollControllerContext.Provider value={state}>{children}</ScrollControllerContext.Provider>;
};
