import React from 'react';
import { Text } from '@components/typography';
import { StyledNavMenuLink } from './Header.styles';
import { HeaderBlogMenuLinkProps } from './Header.types';
import { headerWrapExperiment } from './HeaderWrapExperiment';

const HeaderBlogMenuLink: React.FC<HeaderBlogMenuLinkProps> = ({ idx, id, title, to, href, target, experimentKey, isVisible }) => {
  const link = href ? href : to ? to : null;
  const Wrapper = headerWrapExperiment(experimentKey);
  return link ? (
    <Wrapper key={idx}>
      <StyledNavMenuLink href={link} id={id} target={target} variant="navLink" isVisible={isVisible}>
        <Text variant="featureHed20" tagName="span" style={{ lineHeight: '36px' }}>
          {title}
        </Text>
      </StyledNavMenuLink>
    </Wrapper>
  ) : null;
};

HeaderBlogMenuLink.displayName = 'HeaderBlogMenuLink';

export default HeaderBlogMenuLink;
