import { getCreateEventUrl } from '@utils/urlHelpers.util';
import React, { createContext, useContext, useMemo } from 'react';

const GetStartedButtonContext = createContext<{ href: string } | undefined>(undefined);

export const GetStartedButtonProvider: React.FC<{ href: string }> = ({ href, children }) => {
  const value = useMemo(() => ({ href }), [href]);
  return <GetStartedButtonContext.Provider value={value}>{children}</GetStartedButtonContext.Provider>;
};

export const useGetStartedButtonHref = (page?: MarcomPageName) => {
  const context = useContext(GetStartedButtonContext);
  if (!context) {
    return getCreateEventUrl(page);
  }
  return context.href;
};
