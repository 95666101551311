import React from 'react';
import { ColumnsOuter, ColumnsWrapper } from './Columns.styles';
import { MarcomMarginProps } from 'styled-system';
import { ColumnsContextProvider } from './Columns.context';
import { Column } from './Column';
import { MediaQueries } from '@components/themes';
import { AlignItemsProperty } from 'csstype';

export interface ColumnsProps
  extends MarcomMarginProps,
    Readonly<{
      /**
       * Horizontally center the children.
       * @default false
       */
      centered?: boolean;
      /**
       * Vertically align the children.
       * @example
       * verticalAlign="center"
       */
      verticalAlign?: AlignItemsProperty;

      children: React.ReactNode;

      className?: string;

      /**
       * The fluid property specifies whether the containing element should use 100% it's given space across all viewports.
       * It can also be set per breakpoint - the container will be fluid until the specified breakpoint
       *
       * @default false
       *
       * @example
       * fluid={true} -> 100% width at all view viewports
       * fluid={'md'} -> 100% width until md breakpoint, then max-width is set
       */
      fluid?: boolean | keyof MediaQueries;

      /**
       * While <Columns /> can have as many nested <Column /> components and the width per Column is
       * distributed evenly, you can set this to `true` to allow this component to handle more children
       * than would fit in a single row as an alternative to creating another `<Columns />` Grouping.
       *
       * @default false
       */
      multiline?: boolean;

      /**
       * By default (and as a driving function towards mobile-first development), columns are activated from `tablet` onwards.
       * To have columns work on mobile, pass `false`.
       * @default true
       */
      stackOnMobile?: boolean;
    }> {}

function Columns({
  children,
  className,
  centered = false,
  fluid = false,
  multiline = false,
  stackOnMobile = true,
  verticalAlign,
  ...responsiveStyleProps
}: ColumnsProps) {
  return (
    <ColumnsContextProvider columnGap={'sm'} stackOnMobile={stackOnMobile}>
      <ColumnsOuter className={className} marginX={['auto']} paddingX={[8, 5, 6]} fluid={fluid} {...responsiveStyleProps}>
        <ColumnsWrapper
          className={className}
          marginX={[-3, undefined, -4]}
          centered={centered}
          multiline={multiline}
          stackOnMobile={stackOnMobile}
          alignItems={verticalAlign}
        >
          {children}
        </ColumnsWrapper>
      </ColumnsOuter>
    </ColumnsContextProvider>
  );
}

Columns.displayName = 'Columns';
Columns.Column = Column;

export { Columns };
